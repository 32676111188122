import React from 'react';
import {Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import {Spinner} from 'spectra-logic-ui/components';
import {commafy} from 'spectra-logic-ui/helpers/number';

const useStyles = makeStyles({
  root: {
    whiteSpace: 'nowrap',
  },
  text: {
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  controls: {
    cursor: 'pointer',
    padding: '3px 5px 3px 5px',
    '&:hover': {
      backgroundColor: '#e3e3e3',
      borderRadius: '5px',
    },
  },
});

export type PaginatorProps = {
  from: number;
  to: number;
  prev: (...args: any) => any;
  next: (...args: any) => any;
  showNext: boolean;
}

// Paginator is a pagination widget.  It displays "< Viewing {from} to {to} >".
// Clicking on "<" and ">" calls the "prev" and "next" callbacks, respectively.
// "<" is only rendered if "from" is greater than 1.  ">" is only rendered if
// "showNext" is true.
const Paginator = ({prev, from, to, next, showNext}: PaginatorProps) => {
  const classes = useStyles();

  if (from === 1 && !showNext) {
    return null;
  }

  let renderedTo;
  if (to !== undefined && to !== null) {
    if (to < 0) {
      renderedTo = <Spinner />;
    } else {
      renderedTo = commafy(to);
    }
  }

  return (
    <span className={classes.root}>
      <Typography>
        {from > 1 && <Prev onClick={prev} />}
        <span className={classes.text}>Viewing {commafy(from)} to {renderedTo}</span>
        {showNext && <Next onClick={next} />}
      </Typography>
    </span>
  );
};

const Prev = (props: any) => {
  const classes = useStyles();
  return <span className={classes.controls} {...props}>&lt;</span>;
};

const Next = (props: any) => {
  const classes = useStyles();
  return <span className={classes.controls} {...props}>&gt;</span>;
};

export default Paginator;
