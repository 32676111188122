import React from 'react';
import {useForm, useFormState} from 'react-final-form';

import {Checkbox as MuiCheckbox, Divider, FormControlLabel, Grid, Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import {Tooltip, WizardPage} from 'spectra-logic-ui/components';

import {Account, Bucket} from '@/types';
import {BucketOwnershipControls, FormActionType} from '@/enum';
import Checkbox from '@/components/form/checkbox';
import DialogDescription from '@/components/form/dialog_description';
import TextField from '@/components/form/text_field';
import {BucketVersioning} from '@/buckets/form/enum';
import FormSingleSelect from '@/components/form/single_select';

type PageProps = {
  title: string;
  action: string;
  bucket?: Bucket;
  accounts: Account[];
  validate: (formValues: any) => object;
}

const useStyles = makeStyles({
  select: {
    maxWidth: '300px',
  },
  container: {
    display: 'inline-flex',
  },
  checkBox: {
    marginRight: '0px',
  },
});

const useControlTipStyles = makeStyles({
  divider: {
    margin: '10px 0',
  },
  option: {
    marginBottom: 10,
  },
  optionHeader: {
    fontStyle: 'italic',
    marginBottom: 2,
  },
  optionContent: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginLeft: 10,
    },
  },
  optionItem: {
    display: 'flex',
    alignItems: 'center',
  },
});

export const controlTranslations = {
  [BucketOwnershipControls.BUCKET_OWNER_ENFORCED]: 'ACLs Disabled',
  [BucketOwnershipControls.BUCKET_OWNER_PREFERRED]: 'Bucket Owner Preferred',
  [BucketOwnershipControls.OBJECT_WRITER]: 'Object Writer',
};

export const ControlTip = () => {
  const classes = useControlTipStyles();

  return (
    <div>
      <Typography variant='body2'>
        Determines ownership of new objects and how Access Control Lists (ACLs) are used.
      </Typography>
      <Divider className={classes.divider} />
      <div className={classes.option}>
        <div className={classes.optionHeader}>
          <Typography variant='body2' display='inline'>
            <strong>{controlTranslations[BucketOwnershipControls.BUCKET_OWNER_ENFORCED]}</strong>
          </Typography>
        </div>
        <div className={classes.optionContent}>
          <Typography variant='body2' display='inline'>
            ACLs are disabled. New objects written to this bucket are always owned by the bucket owner.
            Access to this bucket and its objects is specified using only policies.
            This is the recommended setting.
          </Typography>
        </div>
      </div>
      <div className={classes.option}>
        <div className={classes.optionHeader}>
          <Typography variant='body2' display='inline'>
            <strong>{controlTranslations[BucketOwnershipControls.BUCKET_OWNER_PREFERRED]}</strong>
          </Typography>
        </div>
        <div className={classes.optionContent}>
          <Typography variant='body2' display='inline'>
            If new objects written to this bucket specify the <i>bucket-owner-full-control</i> canned ACL,
            they are owned by the bucket owner. Otherwise, they are owned by the object writer.
            Access to this bucket and its objects can be specified using either ACLs or policies.
          </Typography>
        </div>
      </div>
      <div className={classes.option}>
        <div className={classes.optionHeader}>
          <Typography variant='body2' display='inline'>
            <strong>{controlTranslations[BucketOwnershipControls.OBJECT_WRITER]}</strong>
          </Typography>
        </div>
        <div className={classes.optionContent}>
          <Typography variant='body2' display='inline'>
            The object writer remains the object owner. Access to this bucket and its objects can be
            specified using either ACLs or policies.
          </Typography>
        </div>
      </div>
    </div>
  );
};

const compressTooltip = `
Enabling compression reduces storage requirements when objects placed in the
bucket are compressible. Compression is not recommended for objects with
non-compressible data (e.g. ZIP, media files), since it can negatively impact
performance.
`;

const archiveTooltip = `
AWS S3 Glacier restore operations can be hidden to allow clients that do
not fully support Glacier to still operate. This setting automatically
initiates a restore when a request is made for an object that requires
it. A service unavailable (503) error is returned instead of the standard
InvalidObjectState (403) error. This setting interferes with the operation
of clients that properly support AWS S3 Glacier restores.
`;

const ParametersPage = ({title, action, bucket, accounts, ...others}: PageProps) => {
  const classes = useStyles();
  const form = useForm();
  const formState = useFormState();
  const versioning = formState.values.versioning;
  const locking = formState.values.locking;

  const description = action == FormActionType.EDIT ? 'Edit bucket parameters.' : 'Configure your new bucket.';

  const handleVersioningChange = () => {
    let value: BucketVersioning | undefined = BucketVersioning.ENABLED;
    if (versioning === BucketVersioning.ENABLED) {
      // User is unchecking the enable-versioning checkbox.
      if (action === FormActionType.CREATE) {
        // Users can only create a bucket with versioning enabled or disabled. Checking
        // the enable-versioning box and then unchecking it should set it back to disabled
        // (undefined == disabled).
        value = undefined;
      } else {
        // The user is editing an existing bucket's versioning.
        if (bucket && bucket.versioning === BucketVersioning.DISABLED) {
          // The user is editing a bucket whose versioning was disabled, they checked the
          // enable-versioning checkbox, and then unchecked it. We should leave versioning
          // disabled.
          value = undefined;
        } else {
          // The user is editing a bucket whose versioning was enabled, and they unchecked
          // the enable-versioning checkbox. They could also be editing a bucket whose
          // versioning was suspended, checked the box, and then unchecked the box.
          value = BucketVersioning.SUSPENDED;
        }
      }
    }
    form.mutators.setFormValue('versioning', value);
    if (value !== BucketVersioning.ENABLED && locking) {
      form.mutators.setFormValue('locking', false);
    }
  };

  const handleLockingChange = () => {
    const value = !locking;
    form.mutators.setFormValue('locking', value);
  };

  const controlOptions = [{
    key: BucketOwnershipControls.BUCKET_OWNER_ENFORCED,
    text: controlTranslations[BucketOwnershipControls.BUCKET_OWNER_ENFORCED] + ' (recommended)',
  }, {
    key: BucketOwnershipControls.BUCKET_OWNER_PREFERRED,
    text: controlTranslations[BucketOwnershipControls.BUCKET_OWNER_PREFERRED],
  }, {
    key: BucketOwnershipControls.OBJECT_WRITER,
    text: controlTranslations[BucketOwnershipControls.OBJECT_WRITER],
  }];

  const ownerOptions = [];
  for (let i = 0; i < accounts.length; i++) {
    const account = accounts[i];
    const option = {key: account.canonicalId, text: account.username || account.id};
    if (account.default) {
      ownerOptions.unshift(option);
    } else {
      ownerOptions.push(option);
    }
  }

  return (
    <WizardPage
      title={title} {...others}
      description={<DialogDescription>{description}</DialogDescription>}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            name='name' label='Bucket Name'
            disabled={action === FormActionType.EDIT}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            label='Enable Versioning'
            control={
              <MuiCheckbox
                checked={versioning === BucketVersioning.ENABLED} onChange={handleVersioningChange}
                disabled={bucket && bucket.locking}
              />
            }
          />
          <FormControlLabel
            label='Enable Object Locking' className={classes.checkBox}
            control={
              <MuiCheckbox
                checked={locking} onChange={handleLockingChange}
                disabled={(versioning !== BucketVersioning.ENABLED) || (bucket && bucket.locking)}
              />
            } />
          <Tooltip>
            Enables object locking on this bucket. Versioning must also be enabled in order to enable this.
          </Tooltip>
        </Grid>
        <Grid item xs={12}>
          <Checkbox name='encrypt' label='Enable Encryption' />
          <Checkbox name='compress' label='Enable Compression' tooltip={compressTooltip} />
          <Checkbox name='restore' label='Hide Glacier Operations' tooltip={archiveTooltip} />
        </Grid>
        <Grid item xs={12}>
          <FormSingleSelect className={classes.select} name='owner' label='Bucket Owner'
            options={ownerOptions} disabled={action === FormActionType.EDIT} />
        </Grid>
        <Grid item xs={12}>
          <FormSingleSelect className={classes.select} name='control' label='Object Ownership'
            options={controlOptions} tooltip={<ControlTip />} />
        </Grid>
      </Grid>
    </WizardPage>
  );
};

export default ParametersPage;
