import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import isEmpty from 'is-empty';
import {Divider} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import {Dispatch, Store} from 'spectra-logic-ui';
import {fetchResource} from 'spectra-logic-ui/actions';
import {ApiStatus, Card, Table, Tooltip} from 'spectra-logic-ui/components';
import SiteIcon from 'spectra-logic-ui/icons/Domain';
import {numberToHumanSize} from 'spectra-logic-ui/helpers/number';

import {storageClassString, TargetType} from '@/enum';
import {Location, LocationCapacityMap, StorageUsed, Pool} from '@/types';
import CardHeader from '@/components/card_header';
import LocationSelector from '@/locations/selector';
import {createTypeDisplayName} from '@/storage/storage_rows';
import PartitionsField from '@/usage/partitions_field';
import OnPremiseStorageCharts from '@/usage/on_premise_storage_charts';

type Props = {
  locations?: Location[];
  entities?: StorageUsed[];
  pools?: Pool[];
  capacities?: StorageUsed[];
  fetching?: boolean;
  error?: boolean;
  fetchLocations?: () => Promise<any>;
  fetchSummary?: () => Promise<any>;
  fetchPools?: () => Promise<any>;
  fetchEntities?: () => Promise<any>;
}

const useStyles = makeStyles({
  divider: {
    marginTop: '10px',
    marginBottom: '20px',
  },
  boldTableHeader: {
    fontWeight: 'bold',
  },
});


const LocationChart = (props: Props) => {
  const {
    locations = [],
    entities = [],
    pools = [],
    capacities = [],
    fetching = false,
    error = false,
    fetchLocations,
    fetchEntities,
    fetchPools,
    fetchSummary} = props;
  const [locationID, setLocationID] = useState<string>();
  const classes = useStyles();
  const capacityMap = {} as LocationCapacityMap;
  (Array.isArray(entities) ? entities : []).forEach((c) => {
    const loc = c.location === undefined ? '' : c.location;
    let lc = capacityMap[loc];
    if (Array.isArray(lc)) {
      lc.push(c);
    } else {
      lc = [c];
    }
    capacityMap[loc] = lc;
  });
  let summary = capacityMap[locationID === undefined ? '' : locationID];
  if (summary === undefined) {
    summary = [];
  }
  let locationSummary: StorageUsed[] = [];
  (Array.isArray(capacities) ? capacities : []).forEach((s) => {
    if (locationID !== undefined && s.location === locationID) {
      locationSummary = locationSummary.concat(s);
    }
  });

  useEffect(() => {
    if (fetchLocations !== undefined) {
      fetchLocations();
    }
    if (fetchSummary !== undefined) {
      fetchSummary();
    }
    if (fetchEntities !== undefined) {
      fetchEntities();
    }
    if (fetchPools !== undefined) {
      fetchPools();
    }
  }, []);

  useEffect(() => {
    if (!locationID && locations.length > 0) {
      setLocationID(locations[0].id);
    }
  }, [locations]);

  const handleChange = (id: string) => {
    setLocationID(id);
  };

  if (locations.length === 0) {
    return null;
  }
  type PoolMap = { [key: string]: Pool };
  const poolMap: PoolMap = {};
  pools.forEach((pool) => poolMap[pool.id] = pool);
  const locationStorages: {
    id: string;
    name: string;
    storageType: string;
    rawStorageTarget: TargetType;
    access: string;
    used: string;
    optional: string;
    available: string;
  }[] = [];
  Object.keys(poolMap).length !== 0 && summary.forEach((e) => {
    e.storage !== undefined && e.storage.forEach((storage) => {
      const pool = poolMap[storage.id];
      if (pool !== undefined) {
        const used = e.used === undefined ? 0 : e.optional ? e.used-e.optional : e.used;
        let available = '--';
        if (!pool.link) {
          available = numberToHumanSize(e.total !== undefined ? e.total - used : 0);
        }
        locationStorages.push({
          id: storage.id,
          name: pool.name,
          storageType: createTypeDisplayName(pool),
          rawStorageTarget: pool.target,
          access: storageClassString(pool.storageClass),
          used: numberToHumanSize(storage.data),
          optional: storage.optional === undefined ? '--' : numberToHumanSize(storage.optional),
          available: available,
        });
      }
    });
  });
  locationStorages.sort((a, b) => a.name.localeCompare(b.name));

  const tt = 'Data written to endpoint storage may be compressed. These charts show size after compression.';
  return (
    <Card>
      <CardHeader icon={SiteIcon} tooltip={tt}>Location Capacity</CardHeader>
      <Card.Body>
        <ApiStatus isLoading={fetching} hasError={error} isEmpty={isEmpty(locations)}>
          <LocationSelector
            locations={locations} capacityMap={capacityMap} onSelect={handleChange}
          />
          <Divider className={classes.divider} />
          <div>
            {!isEmpty(locations) && <ApiStatus isEmpty={isEmpty(locationSummary)}>
              <OnPremiseStorageCharts summary={locationSummary} />
              <div>
                <Table>
                  <Table.Header>
                    <Table.Row>
                      <Table.Cell className={classes.boldTableHeader}>Storage</Table.Cell>
                      <Table.Cell className={classes.boldTableHeader}>Type</Table.Cell>
                      <Table.Cell className={classes.boldTableHeader}>Storage Class</Table.Cell>
                      <Table.Cell className={classes.boldTableHeader}>Partitions</Table.Cell>
                      <Table.Cell className={classes.boldTableHeader}>Used</Table.Cell>
                      <Table.Cell className={classes.boldTableHeader}>
                        Optional
                        <Tooltip>
                          <span>
                            Amount used by Vail for storing optional clones.
                          </span>
                        </Tooltip>
                      </Table.Cell>
                      <Table.Cell className={classes.boldTableHeader}>
                        Available
                        <Tooltip>
                          <span>
                            BlackPearl storage is over-provisioned,
                            and available space may be consumed by more than one storage.
                            Available capacity does not account for capacity used by file system overhead.
                          </span>
                        </Tooltip>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Header>
                  <React.Fragment>
                    <Table.Body>
                      {locationStorages.map((s) =>
                        <Table.Row key={'locationPool_' + s.id}>
                          <Table.Cell>{s.name}</Table.Cell>
                          <Table.Cell>{s.storageType}</Table.Cell>
                          <Table.Cell>{s.access}</Table.Cell>
                          <Table.Cell>
                            {s.rawStorageTarget == TargetType.BLACKPEARL ?
                              <PartitionsField id={s.id}/> :
                              <div>--</div>
                            }
                          </Table.Cell>
                          <Table.Cell>{s.used}</Table.Cell>
                          <Table.Cell>{s.optional}</Table.Cell>
                          <Table.Cell>{s.available}</Table.Cell>
                        </Table.Row>,
                      )}
                    </Table.Body>
                  </React.Fragment>
                </Table>
              </div>
            </ApiStatus>}
          </div>
        </ApiStatus>
      </Card.Body>
    </Card>
  );
};

const mapStateToProps = (state: Store) => {
  const locationsResource = state.resources['locations'] || {};
  const locations = locationsResource.data || [];
  const pools = state.resources['storage'] || {};
  const capacityResource = state.resources['usage/locations'] || {};
  const entityResource = state.resources['usage/entities'] || {};
  return {
    locations: locations.filter((l: Location) => l.id !== '0'),
    entities: entityResource.data,
    pools: pools.data || [],
    capacities: capacityResource.data,
    fetching: locationsResource.fetching || entityResource.fetching || capacityResource.fetching,
    error: locationsResource.error || entityResource.error,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchLocations: () => dispatch(fetchResource('locations')),
  fetchSummary: () => (dispatch(fetchResource('usage/locations'))),
  fetchPools: () => (dispatch(fetchResource('storage'))),
  fetchEntities: () => (dispatch(fetchResource('usage/entities'))),
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationChart);
