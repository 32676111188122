import React from 'react';
import {connect} from 'react-redux';
import {useFormState} from 'react-final-form';
import isEmpty from 'is-empty';

import makeStyles from '@mui/styles/makeStyles';

import {WizardPage} from 'spectra-logic-ui/components';
import {Store} from 'spectra-logic-ui';

import {BucketOwnershipControls} from '@/enum';
import {Acl, Account, LifecycleV2, DefaultRetention} from '@/types';
import DialogDescription from '@/components/form/dialog_description';
import NoteCard from '@/components/note_card';
import {controlTranslations} from '@/buckets/form/pages/parameters';
import {renderAcl} from '@/buckets/details/acls';

type PageProps = {
  title: string;
  accounts?: Account[];
  lifecycles?: LifecycleV2[];
}

const useStyles = makeStyles({
  errorCard: {
    marginTop: 10,
  },
  content: {
    '& > *': {
      marginTop: 15,
    },
  },
  noneAndPolicy: {
    padding: '6px 16px 6px 16px',
  },
});

const AccessControls = ({block, ignore, control, list=[]}: {block: boolean; ignore: boolean;
  control: BucketOwnershipControls; list: Acl[];}) => {
  const classes = useStyles();

  if (control === BucketOwnershipControls.BUCKET_OWNER_ENFORCED) {
    return <div className={classes.noneAndPolicy}>{controlTranslations[control]}</div>;
  }

  return (
    <>
      <NoteCard.Table>
        <NoteCard.TableRow name='Block Public ACLs' value={block ? 'Enabled' : 'Disabled'} />
        <NoteCard.TableRow name='Ignore Public ACLs' value={ignore ? 'Enabled' : 'Disabled'} />
        <>
          {list.map((acl, index) => (
            <NoteCard.TableRow key={index} name={acl.id} value={renderAcl(acl)} />
          ))}
        </>
      </NoteCard.Table>
      {list.length === 0 && <div className={classes.noneAndPolicy}>None</div>}
    </>
  );
};

const ConfirmPage = ({title, accounts = [], lifecycles = [], ...others}: PageProps) => {
  const formState = useFormState();
  const name = formState.values.name;
  const owner = formState.values.owner;
  const encrypt = formState.values.encrypt;
  const compress = formState.values.compress;
  const restore = formState.values.restore;
  const versioning = formState.values.versioning;
  const locking = formState.values.locking;
  const defaultRetention: DefaultRetention = formState.values.defaultRetention;
  const control: BucketOwnershipControls = formState.values.control;
  const blockPublicAcls = formState.values.blockPublicAcls;
  const ignorePublicAcls = formState.values.ignorePublicAcls;
  const acls = formState.values.acls;
  const blockPublicPolicy = formState.values.blockPublicPolicy;
  const restrictPublicBuckets = formState.values.restrictPublicBuckets;
  const policy = formState.values.policy;
  const lifecycleId = formState.values.lifecycle;

  const account = accounts.find((a) => a.canonicalId === owner);
  const lifecycle = lifecycles.find((l) => l.id === lifecycleId) || {} as LifecycleV2;
  const classes = useStyles();
  let compliance = '--';
  let days = '--';
  let years = '--';
  if (defaultRetention !== undefined) {
    if (defaultRetention.compliance !== undefined) {
      compliance = defaultRetention.compliance ? 'Compliance' : 'Governance';
    }
    if (defaultRetention.days !== undefined) {
      days = defaultRetention.days.toString();
    }
    if (defaultRetention.years !== undefined) {
      years = defaultRetention.years.toString();
    }
  }

  return (
    <WizardPage
      title={title} {...others}
      description={<DialogDescription>Verify your configuration is correct.</DialogDescription>}
    >
      {formState.submitErrors && formState.submitErrors.policy &&
        <div className='error'>Policy error: {formState.submitErrors.policy}</div>}
      <div className={classes.content}>
        <NoteCard title='Parameters'>
          <NoteCard.Table>
            <NoteCard.TableRow name='Name' value={name} />
            <NoteCard.TableRow name='Owner' value={account ? (account.username || account.id) : owner} />
            <NoteCard.TableRow name='Encryption' value={encrypt ? 'Enabled' : 'Disabled'} />
            <NoteCard.TableRow name='Compression' value={compress ? 'Enabled' : 'Disabled'} />
            <NoteCard.TableRow name='Hide Glacier Operations' value={restore ? 'Enabled' : 'Disabled'} />
            <NoteCard.TableRow name='Versioning' value={versioning || 'Disabled'} />
            <NoteCard.TableRow name='Locking' value={locking ? 'Enabled' : 'Disabled'} />
            <NoteCard.TableRow name='Locking Mode' value={compliance} />
            <NoteCard.TableRow name='Days to Retain' value={days} />
            <NoteCard.TableRow name='Years to Retain' value={years} />
            <NoteCard.TableRow name='Object Owership' value={controlTranslations[control]} />
          </NoteCard.Table>
        </NoteCard>
        <NoteCard title='Policy'>
          <NoteCard.Table>
            <NoteCard.TableRow name='Block Public Policy' value={blockPublicPolicy ? 'Enabled' : 'Disabled'} />
            <NoteCard.TableRow name='Restrict Public Buckets' value={restrictPublicBuckets ? 'Enabled' : 'Disabled'} />
          </NoteCard.Table>
          <div className={classes.noneAndPolicy}>
            {isEmpty(policy) ? 'None' : <pre>{JSON.stringify(policy, null, 2)}</pre>}
          </div>
        </NoteCard>
        <NoteCard title='Access Control List'>
          {<AccessControls block={blockPublicAcls} ignore={ignorePublicAcls} control={control} list={acls} />}
        </NoteCard>
        <NoteCard title='Lifecycle'>
          {isEmpty(lifecycle) ? <div className={classes.noneAndPolicy}>None</div> :
            <NoteCard.Table>
              <NoteCard.TableRow name='Name' value={lifecycle.name}/>
            </NoteCard.Table>
          }
        </NoteCard>
      </div>
    </WizardPage>
  );
};

const mapStateToProps = (state: Store) => {
  const lifecyclesResource = state.resources.lifecycles || {};
  return {
    lifecycles: lifecyclesResource.data,
  };
};

export default connect(mapStateToProps, undefined)(ConfirmPage);
