export enum ValidationError {
  CANNOT_BE_BLANK = 'Cannot be blank',
  CANNOT_CONTAIN_LEADING_OR_TRAILING_SPACES = 'Cannot contain leading or trailing spaces',
  MUST_NOT_CONTAIN_SPACES = 'Must not contain spaces',
  MUST_BE_A_NUMBER = 'Must be a number',
  MUST_BE_UNIQUE = 'Must be unique',
  CANNOT_BE_A_NEGATIVE_NUMBER = 'Cannot be a negative number',
  CANNOT_CONTAIN_A_DECIMAL_POINT = 'Cannot contain a decimal point',
}

type ValidateNameOptions = {
  maxBytes?: number;
}

type ValidateNumberOptions = {
  maxValue?: number;
  minValue?: number;
}

export const validateName = (value: string | undefined, options = {} as ValidateNameOptions) => {
  const {maxBytes = 1000} = options;
  if (!value || value.trim() === '') {
    return ValidationError.CANNOT_BE_BLANK;
  }
  return validateStringMaxBytes(value, maxBytes);
};

export const validateNumber = (value: string | number, options = {} as ValidateNumberOptions) => {
  const {maxValue = 2147483647, minValue = 1} = options; // max 32-bit positive integer
  const num = Number(value);
  if (!value && value !== '0' && value !== 0) {
    return undefined;
  } else if (typeof value === 'string' && /\s/.test(value)) {
    return ValidationError.MUST_NOT_CONTAIN_SPACES;
  } else if (/\./.test(value.toString())) {
    return ValidationError.CANNOT_CONTAIN_A_DECIMAL_POINT;
  } else if (isNaN(num)) {
    return ValidationError.MUST_BE_A_NUMBER;
  } else if (num < 0) {
    return ValidationError.CANNOT_BE_A_NEGATIVE_NUMBER;
  } else if (num > maxValue) {
    return `Cannot be greater than ${maxValue}`;
  } else if (num < minValue) {
    return `Cannot be less than ${minValue}`;
  }
  return undefined;
};

export const validateStringMaxBytes = (value: string | undefined, maxBytes: number) => {
  if (!value) {
    return undefined;
  }
  if ((new TextEncoder().encode(value).length) > maxBytes) {
    return `Cannot be longer than ${maxBytes} bytes`;
  }
  return undefined;
};
