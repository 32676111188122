import isEmpty from 'is-empty';

import {LifecycleV2} from '@/types';
import {validateName, validateNumber, validateStringMaxBytes, ValidationError} from '@/validate';
import {FormValues} from '@/lifecycle/form/types';

const maxNameBytes = 255;

export const createParametersValidator = (lifecycles: LifecycleV2[]) => {
  return ({name, uploads, description}: FormValues) => {
    const errors = {} as any;

    errors.name = validateName(name, {maxBytes: maxNameBytes});
    if (!errors.name && lifecycles.find((l) => name.trim() === l.name)) {
      errors.name = ValidationError.MUST_BE_UNIQUE;
    }

    errors.uploads = validateNumber(uploads);

    errors.description = validateStringMaxBytes(description, 255);

    return errors;
  };
};

// validateRules validates the rules in the lifecycle form. Most of this validation
// is done server-side. The only parts that are validated here are things that can't
// be done server-side.
export const validateRules = ({rules}: FormValues) => {
  const ruleDestinations = {count: undefined, storage: undefined};
  const ruleSchedule = {days: undefined};
  const ruleError = {name: undefined, destinations: ruleDestinations, schedule: ruleSchedule};
  const filterError = {key: undefined, value: undefined, count: undefined};
  const errors = {rules: [] as typeof ruleError[]} as any;

  if (!isEmpty(rules)) {
    rules.forEach((rule, ruleIndex) => {
      errors.rules[ruleIndex] = Object.assign({}, ruleError);
      errors.rules[ruleIndex].destinations = Object.assign({}, ruleDestinations);
      errors.rules[ruleIndex].schedule = Object.assign({}, ruleSchedule);
      errors.rules[ruleIndex].filters = [] as typeof filterError[];

      if (!isEmpty(rule.filters)) {
        rule.filters.forEach((filter, filterIndex) => {
          errors.rules[ruleIndex].filters[filterIndex] = Object.assign({}, filterError);
          errors.rules[ruleIndex].filters[filterIndex].key = validateStringMaxBytes(filter.key, 255);
          errors.rules[ruleIndex].filters[filterIndex].value = validateStringMaxBytes(filter.value, 255);

          // filter count is an int server-side. Thus, the server-side validations
          // won't see these kinds of invalid options a user might-have entered.
          if (filter.count && filter.count.length > 0) {
            const num = Number(filter.count);
            if (/\s/.test(filter.count)) {
              errors.rules[ruleIndex].filters[filterIndex].count = ValidationError.MUST_NOT_CONTAIN_SPACES;
            } else if (/\./.test(filter.count)) {
              errors.rules[ruleIndex].filters[filterIndex].count = ValidationError.CANNOT_CONTAIN_A_DECIMAL_POINT;
            } else if (isNaN(num)) {
              errors.rules[ruleIndex].filters[filterIndex].count = ValidationError.MUST_BE_A_NUMBER;
            }
          }
        });
      }
    });
  }
  return errors;
};
