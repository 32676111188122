import React from 'react';

import {Table} from 'spectra-logic-ui/components';
import {dateTimeLong} from 'spectra-logic-ui/helpers/date';

import {Account, Bucket, LifecycleV2} from '@/types';
import {controlTranslations} from '@/buckets/form/pages/parameters';

type Props = {
  bucket: Bucket;
  linkedPool: string;
  lifecycles: LifecycleV2[];
  accounts: Account[];
}

const PropertiesDetails = ({bucket, lifecycles, linkedPool, accounts}: Props) => {
  const lifecycle = lifecycles.find((l) => l.id === bucket.lifecycle) || {} as LifecycleV2;
  const account = accounts.find((a) => a.canonicalId === bucket.owner);
  let compliance = '--';
  let timeValue = '--';
  if (bucket.defaultRetention !== undefined) {
    compliance = bucket.defaultRetention.compliance ? 'Compliance' : 'Governance';
    let units = '';
    if (bucket.defaultRetention.years !== undefined && bucket.defaultRetention.years > 0) {
      units = ' year';
      units += bucket.defaultRetention.years > 1 ? 's' : '';
      timeValue = bucket.defaultRetention.years.toString() + units;
    } else if (bucket.defaultRetention.days !== undefined && bucket.defaultRetention.days > 0) {
      units = ' day';
      units += bucket.defaultRetention.days > 1 ? 's' : '';
      timeValue = bucket.defaultRetention.days.toString() + units;
    }
  }

  const bucketProps = [
    {key: 'Owner', value: account ? (account.username || account.id) : bucket.owner},
    {key: 'Linked Storage', value: linkedPool},
    {key: 'Versioning', value: bucket.versioning || 'Disabled'},
    {key: 'Object Locking', value: bucket.locking ? 'Enabled' : 'Disabled'},
    {key: 'Encryption', value: bucket.encrypt ? 'Enabled' : 'Disabled'},
    {key: 'Compression', value: bucket.compress ? 'Enabled' : 'Disabled'},
    {key: 'Hide Glacier Operations', value: bucket.restore ? 'Enabled' : 'Disabled'},
    {key: 'Object Ownership', value: controlTranslations[bucket.control]},
    {key: 'Lifecycle', value: bucket.lifecycle ? lifecycle.name : '--'},
    {key: 'Creation date', value: dateTimeLong(bucket.created)},
    {key: 'Default Retention Mode', value: compliance},
    {key: 'Default Retention Period', value: timeValue},
  ];

  return (
    <Table>
      <Table.Body>
        {bucketProps.map((kv) => (
          <Table.Row key={kv.key}>
            <Table.Cell>{kv.key}</Table.Cell>
            <Table.Cell>{kv.value}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default PropertiesDetails;
