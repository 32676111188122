import React from 'react';

import {Popover, Table, Tooltip} from 'spectra-logic-ui/components';

import {TargetType} from '@/enum';
import {BPProperties, CloudProperties, Pool, TargetItemDetails} from '@/types';
import {isArchival} from '@/storage/archive';
import {createTypeDisplayName} from '@/storage/storage_rows';
import {Dispatch, Store} from 'spectra-logic-ui';
import {fetchResource} from 'spectra-logic-ui/actions';
import {connect} from 'react-redux';
import {isCloud} from '@/storage/cloud';
import {isBP} from '@/storage/on_site_storage';

type Props = {
  storage: Pool;
  itemDetails?: TargetItemDetails;
  fetchItemDetails?: Function;
}

export const cloneRestoreTooltip = 'The restore to new clone option creates an additional data clone on ' +
  'different storage, rather than using resources on existing archival storage.';

export const recoverableTooltip = 'Enabling third-party recovery causes additional information'+
  ' to be written, allowing full objects to be generated from the content of the storage.'+
  ' This option impacts performance and increases storage usage.';

const PropertiesDetails = ({storage, fetchItemDetails, itemDetails}: Props) => {
  React.useEffect(() => {
    if (fetchItemDetails !== undefined) {
      fetchItemDetails();
    }
  }, [storage]);

  let owner;
  let policy;
  let partitions;
  let region;
  let url;
  let unsafe;
  let virtualHost;
  if (itemDetails && itemDetails.properties) {
    if (isBP(storage)) {
      const bp = itemDetails.properties as BPProperties;
      if (bp.policy) {
        if (bp.policyName) {
          policy = <Popover anchor={<span>{bp.policyName}</span>}>
            <span>ID: {bp.policy}</span>
          </Popover>;
        } else {
          owner = bp.policy;
        }
      }
      if (bp.partitions) {
        partitions = bp.partitions.join(', ');
      }
    }
    if (isCloud(storage)) {
      let cloud = {} as CloudProperties;
      cloud = itemDetails.properties as CloudProperties;
      if (cloud.owner) {
        if (cloud.ownerName) {
          owner = <Popover anchor={<span>{cloud.ownerName}</span>}>
            <span>ID: {cloud.owner}</span>
          </Popover>;
        } else {
          owner = cloud.owner;
        }
      }
      if (cloud.region) {
        region = cloud.region;
      }
      if (cloud.endpoint) {
        url = cloud.endpoint;
      }
      if (cloud.unsafe !== undefined) {
        unsafe = cloud.unsafe;
      }
      if (storage.target === TargetType.S3OTHER) {
        virtualHost = cloud.virtualHost;
      }
    }
  }

  // Show the recoverable parameter for all storage types that allow
  // the user to set it.
  const showRecoverable = storage.target !== TargetType.BPLINK;

  return (
    <Table>
      <Table.Body>
        <Table.Row>
          <Table.Cell>ID</Table.Cell>
          <Table.Cell>{storage.id}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Type</Table.Cell>
          <Table.Cell>{createTypeDisplayName(storage)}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Storage Class</Table.Cell>
          <Table.Cell>{storage.storageClass}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Status</Table.Cell>
          <Table.Cell>{storage.status}</Table.Cell>
        </Table.Row>
        {showRecoverable &&
            <Table.Row>
              <Table.Cell>Third-party Recovery<Tooltip>{recoverableTooltip}</Tooltip></Table.Cell>
              <Table.Cell>{storage.recoverable ? 'Enabled' : 'Disabled'}</Table.Cell>
            </Table.Row>
        }
        {isArchival(storage) &&
          <Table.Row>
            <Table.Cell>Restore To New Clone<Tooltip>{cloneRestoreTooltip}</Tooltip></Table.Cell>
            <Table.Cell>{storage.cloneRestore ? 'Enabled' : 'Disabled'}</Table.Cell>
          </Table.Row>
        }
        {storage.item && storage.target !== TargetType.VOLUME &&
          <Table.Row>
            <Table.Cell>{storage.target === TargetType.AZURE ? 'Container' : 'Bucket'}</Table.Cell>
            <Table.Cell>{storage.item}</Table.Cell>
          </Table.Row>
        }
        {storage.link &&
          <Table.Row>
            <Table.Cell>Link to Bucket</Table.Cell>
            <Table.Cell>{storage.link}</Table.Cell>
          </Table.Row>
        }
        {url &&
          <Table.Row>
            <Table.Cell>Data Path Endpoint</Table.Cell>
            <Table.Cell>{url}</Table.Cell>
          </Table.Row>
        }
        {unsafe !== undefined &&
          <Table.Row>
            <Table.Cell>Skip TLS Verification</Table.Cell>
            <Table.Cell>{unsafe ? 'Yes' : 'No'}</Table.Cell>
          </Table.Row>
        }
        {region &&
          <Table.Row>
            <Table.Cell>Region</Table.Cell>
            <Table.Cell>{region}</Table.Cell>
          </Table.Row>
        }
        {storage.target === TargetType.S3OTHER &&
          <Table.Row>
            <Table.Cell>Addressing Style</Table.Cell>
            <Table.Cell>{virtualHost ? 'Virtual Hosted' : 'Path'}</Table.Cell>
          </Table.Row>
        }
        {owner &&
          <Table.Row>
            <Table.Cell>{storage.target === TargetType.AZURE ? 'Storage Account' : 'Bucket Owner'}</Table.Cell>
            <Table.Cell>{owner}</Table.Cell>
          </Table.Row>
        }
        {policy &&
          <Table.Row>
            <Table.Cell>Data Policy</Table.Cell>
            <Table.Cell>{policy}</Table.Cell>
          </Table.Row>
        }
        {partitions &&
          <Table.Row>
            <Table.Cell>Data Partitions</Table.Cell>
            <Table.Cell>{partitions}</Table.Cell>
          </Table.Row>
        }
        {(((storage.target === TargetType.S3 ||
            storage.target == TargetType.S3OTHER) && !!storage.link) || storage.pauseNotifications) &&
          <Table.Row>
            <Table.Cell>Notifications</Table.Cell>
            <Table.Cell>{storage.pauseNotifications ? 'Paused' : 'Enabled'}</Table.Cell>
          </Table.Row>
        }
        {storage.cautionThreshold &&
            <Table.Row>
              <Table.Cell>Caution Threshold</Table.Cell>
              <Table.Cell>{storage.cautionThreshold}%</Table.Cell>
            </Table.Row>
        }
        {storage.warningThreshold &&
            <Table.Row>
              <Table.Cell>Warning Threshold</Table.Cell>
              <Table.Cell>{storage.warningThreshold}%</Table.Cell>
            </Table.Row>
        }
        {storage.optionalData &&
            <Table.Row>
              <Table.Cell>Optional Data</Table.Cell>
              <Table.Cell>{storage.optionalData}%</Table.Cell>
            </Table.Row>
        }
        {storage.verificationRunning &&
          <Table.Row>
            <Table.Cell>Verification</Table.Cell>
            <Table.Cell>In Progress</Table.Cell>
          </Table.Row>
        }
      </Table.Body>
    </Table>
  );
};

const mapStateToProps = (state: Store, ownProps: Props) => {
  const itemDetails = state.resources['storage/' + ownProps.storage.id + '/item'] || {};
  return {itemDetails: itemDetails.data};
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: Props) => ({
  fetchItemDetails: () => dispatch(fetchResource('storage/' + ownProps.storage.id + '/item')),
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertiesDetails);
