import React from 'react';
import {Grid, IconButton} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import {Color} from 'spectra-logic-ui/colors';
import DeleteIcon from 'spectra-logic-ui/icons/Delete';
import AttentionIcon from 'spectra-logic-ui/icons/ErrorOutlineTwoTone';

import TextField from '@/components/form/text_field';
import NoteCard from '@/components/note_card';
import Filters from '@/lifecycle/form/pages/rules/filters';

type Props = {
  ruleField: string;
  index: number;
  onDelete?: () => void;
}

const useStyles = makeStyles({
  listItem: {
    listStyleType: 'none',
    zIndex: 99999,
    marginBottom: 15,
  },
  ruleInfo: {
    padding: 10,
  },
  deleteButton: {
    alignSelf: 'flex-end',
    padding: '0',
    margin: '0',
  },
  buttonSize: {
    padding: 5,
    margin: '0 -5px -5px 5px',
  },
  clonesSelect: {
    width: '100%',
  },
  expirationIcon: {
    width: 20,
    marginLeft: 3,
  },
  expirationText: {
    verticalAlign: 'super',
  },
});

const ExpirationRule = ({ruleField, onDelete}: Props) => {
  const classes = useStyles();
  const tooltip = (
    <React.Fragment>
      <strong>Warning: </strong>
      A deletion rule will delete the object at the specified time.
    </React.Fragment>
  );

  return (
    <li className={classes.listItem}>
      <NoteCard
        title='Deletion Rule' headerColor={Color.RED}
        tooltip={tooltip} PopoverProps={{icon: AttentionIcon}}
      >
        <Grid container spacing={2} className={classes.ruleInfo}>
          <Grid item xs={12}>
            <TextField label='Name' name={`${ruleField}.name`}/>
          </Grid>
          <Grid item xs={12}>
            <Filters expiration={true} ruleField={ruleField} />
          </Grid>
          <Grid item xs={1} className={classes.deleteButton}>
            <IconButton className={classes.buttonSize} onClick={onDelete} size='large' title='Delete rule'>
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      </NoteCard>
    </li>
  );
};

export default ExpirationRule;
