import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

import Card from 'spectra-logic-ui/components/card';
import Table from 'spectra-logic-ui/components/table';
import StorageIcon from 'spectra-logic-ui/icons/Storage';

import {Pool} from '@/types';
import ButtonToolbar from '@/components/button_toolbar';
import CardHeader from '@/components/card_header';
import DeleteDialog from '@/components/delete_dialog';
import AddCloudStorageWizard from '@/storage/form/add/cloud_storage';
import EditStorageWizard from '@/storage/form/edit';
import DeleteStorageWizard from '@/storage/form/delete';
import StorageRows from '@/storage/storage_rows';
import {StorageRowId} from '@/storage/types';
import {isCloud} from '@/storage/cloud';

type Props = {
  selectedPool?: Pool;
  selectedRowId: StorageRowId;
  selectRowId: Function;
  clearSelectedRow: () => any;
  setOpenDetails: Function;
  pools: Pool[];
}

const useStyles = makeStyles({
  nowrap: {'whiteSpace': 'nowrap'},
});

const CloudStorage = ({selectedPool, selectedRowId, selectRowId, clearSelectedRow, setOpenDetails, pools}: Props) => {
  const cloudPools = pools.filter((p) => isCloud(p));
  const deleteDialogDescription = 'Are you sure you want to delete this empty storage?';
  const canEditOrDelete = selectedPool && isCloud(selectedPool);

  const classes = useStyles();

  // StorageRows is only rendered when 'cloudPools.length > 0' so 'No items to display'
  // is shown if there are no cloud pools.
  return (
    <React.Fragment>
      <Card>
        <CardHeader icon={StorageIcon} tooltip='Any storage added will be available for use with Vail lifecycle rules.'>
          Cloud Storage
        </CardHeader>
        <Card.Body>
          <ButtonToolbar>
            <ButtonToolbar.CreateDialogButton title='Add' dialog={AddCloudStorageWizard} onClick={clearSelectedRow} />
            <ButtonToolbar.EditDialogButton
              disabled={!canEditOrDelete}
              dialog={(props: any) => <EditStorageWizard pool={selectedPool} {...props} />}
              onSuccess={clearSelectedRow}
            />
            <ButtonToolbar.DeleteDialogButton
              disabled={!canEditOrDelete}
              dialog={(props: any) => {
                if (selectedPool) {
                  if (selectedPool.empty) {
                    return <DeleteDialog title='Delete Cloud Storage' id={selectedPool.id}
                      name={selectedPool.name} description={deleteDialogDescription}
                      resource='storage' {...props}/>;
                  } else {
                    return <DeleteStorageWizard pool={selectedPool} {...props} />;
                  }
                }
                return null;
              }}
              onSuccess={clearSelectedRow}
            />
          </ButtonToolbar>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.Cell>Name</Table.Cell>
                <Table.Cell className={classes.nowrap}>Type</Table.Cell>
                <Table.Cell className={classes.nowrap}>Storage Class</Table.Cell>
                <Table.Cell className={classes.nowrap}>Status</Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {cloudPools.length > 0 ?
                <StorageRows pools={cloudPools} selectRowId={selectRowId} selectedRowId={selectedRowId}
                  setOpenDetails={() => setOpenDetails(true)}/> : null}
            </Table.Body>
          </Table>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default CloudStorage;
