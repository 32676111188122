import React from 'react';
import {useFormState} from 'react-final-form';
import {FieldArray} from 'react-final-form-arrays';
import {Button} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import {Tooltip, WizardPage} from 'spectra-logic-ui/components';

import DialogDescription from '@/components/form/dialog_description';
import {FormRule} from '@/lifecycle/form/types';
import TransitionRule from '@/lifecycle/form/pages/rules/transition_rule';
import ExpirationRule from '@/lifecycle/form/pages/rules/expiration_rule';

type PageProps = {
  title: string;
  validate: (formValues: any) => object;
}

const useStyles = makeStyles({
  ruleList: {
    listStyleType: 'none',
    padding: 0,
    margin: 0,
  },
  addButtons: {
    marginTop: -5,
  },
});

const RulesContainer = ({fields}: any) => {
  const classes = useStyles();
  return (
    <div>
      <ul className={classes.ruleList}>
        {fields.map((value: string, index: number) => {
          const rule = fields.value[index] as FormRule;
          const RuleCard = rule.expiration ? ExpirationRule : TransitionRule;
          return <RuleCard key={index} ruleField={value} index={index} onDelete={() => fields.remove(index)} />;
        })}
      </ul>
      {fields.length < 5 &&
        <div className={classes.addButtons}>
          <Button type='button' color='primary' onClick={() => fields.push({expiration: false})}>
            New Placement Rule
          </Button>
          |
          <Button type='button' color='primary' onClick={() => fields.push({expiration: true})}>
            New Deletion Rule
          </Button>
        </div>
      }
    </div>
  );
};

const RulesPage = ({title, ...others}: PageProps) => {
  const formState = useFormState();
  const name = formState.values.name;
  return (
    <WizardPage
      title={title} {...others}
      description={
        <DialogDescription
          footnote={
            <React.Fragment>
              <strong>Note:</strong> Rules will be sorted based on schedule and version filters after submission.
              Maximum number of rules is 5.
            </React.Fragment>
          }
        >
          <span>
            Define your rules for <strong>{name}</strong>.
          </span>
          <Tooltip>
            When an object is added to a bucket, rules determine where
            to place the data initially, how to transition placement,
            and when to delete objects. Placement rules add and remove
            clones from storage destinations, but don't alter the bucket
            contents. Deletion rules delete objects and should be used
            with caution.
          </Tooltip>
        </DialogDescription>
      }
    >
      <FieldArray name='rules' component={RulesContainer} />
    </WizardPage>
  );
};

export default RulesPage;
