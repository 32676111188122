import {TargetType} from '@/enum';
import {validateName, validateNumber, ValidationError} from '@/validate';
import {isCloudStorage} from '@/storage/cloud';

type FormValues = {
  target: TargetType;
  endpoint: string;
  name: string;
  item: string;
  url: string;
  accessKey: string;
  secretKey: string;
  arn: string;
  externalid: string;
  credentials: string;
  link: string;
  storageClass: string;
  cautionThreshold: any;
  warningThreshold: any;
  optionalData: any;
  account: string;
  secret: string;
}

const maxNumber = 100;
const maxNumberOptional = 85;

export const validateStorageTarget = ({target}: FormValues) => {
  const errors = {} as any;
  if (!target) {
    errors.target = ValidationError.CANNOT_BE_BLANK;
  }
  return errors;
};

export const validateAuthorization = (
  {target, accessKey, secretKey, arn, externalid, url, credentials, account, secret}: FormValues,
) => {
  const errors = {} as any;

  if (isCloudStorage(target)) {
    if (target === TargetType.S3OTHER) {
      if (!accessKey) {
        errors.accessKey = ValidationError.CANNOT_BE_BLANK;
      }
      if (!secretKey) {
        errors.secretKey = ValidationError.CANNOT_BE_BLANK;
      }
    }
    if (target === TargetType.AZURE) {
      if (!account) {
        errors.account = ValidationError.CANNOT_BE_BLANK;
      }
      if (!secret) {
        errors.secret = ValidationError.CANNOT_BE_BLANK;
      }
    }
    if (target === TargetType.S3) {
      if (!arn && externalid) {
        errors.externalid = 'External ID requires a Role ARN';
      }
    } else if (target === TargetType.S3OTHER) {
      if (!url) {
        errors.url = ValidationError.CANNOT_BE_BLANK;
      }
    } else if (target === TargetType.GOOGLE) {
      if (!credentials) {
        errors.credentials = ValidationError.CANNOT_BE_BLANK;
      }
    }
  }

  return errors;
};

export const validateEditAuthorization = (
  {target, accessKey, secretKey, arn, externalid, credentials, secret}: FormValues,
) => {
  const errors = {} as any;

  if (isCloudStorage(target)) {
    if (target === TargetType.S3OTHER) {
      if (!accessKey) {
        errors.accessKey = ValidationError.CANNOT_BE_BLANK;
      }
      if (!secretKey) {
        errors.secretKey = ValidationError.CANNOT_BE_BLANK;
      }
    }
    if (target === TargetType.AZURE) {
      if (!secret) {
        errors.secret = ValidationError.CANNOT_BE_BLANK;
      }
    }
    if (target === TargetType.S3) {
      if (!arn && externalid) {
        errors.externalid = 'External ID requires a Role ARN';
      }
    } else if (target === TargetType.GOOGLE) {
      if (!credentials) {
        errors.credentials = ValidationError.CANNOT_BE_BLANK;
      }
    }
  }

  return errors;
};

export const validateParameters = (
  {target, name, item, storageClass, link, cautionThreshold, warningThreshold, optionalData}: FormValues,
) => {
  const errors = {} as any;

  if (!item) {
    errors.item = ValidationError.CANNOT_BE_BLANK;
  }

  if (!storageClass) {
    errors.storageClass = ValidationError.CANNOT_BE_BLANK;
  }

  if (target === TargetType.BLACKPEARL || target === TargetType.BPPOLICY || target === TargetType.VOLUME ||
      target === TargetType.BPVOLUME) {
    if (cautionThreshold || cautionThreshold === 0) {
      errors.cautionThreshold = validateNumber(cautionThreshold, {maxValue: maxNumber});
    }
    if (warningThreshold || warningThreshold === 0) {
      errors.warningThreshold = validateNumber(warningThreshold, {maxValue: maxNumber});
    }
    if (cautionThreshold && warningThreshold && !errors.cautionThreshold && !errors.warningThreshold &&
      cautionThreshold >= warningThreshold) {
      errors.cautionThreshold = 'Caution Threshold cannot be equal/above Warning Threshold';
    }
  }

  if (target === TargetType.BPLINK && !link) {
    errors.link = ValidationError.CANNOT_BE_BLANK;
  }

  if ((target === TargetType.VOLUME || target === TargetType.BPVOLUME) && (optionalData || optionalData === 0)) {
    errors.optionalData = validateNumber(optionalData, {maxValue: maxNumberOptional});
  }

  errors.name = validateName(name);

  return errors;
};

export const validateEditParameters = (
  {target, name, cautionThreshold, warningThreshold, optionalData}: FormValues) => {
  const errors = {} as any;

  if (target === TargetType.BLACKPEARL || target === TargetType.BPPOLICY || target === TargetType.VOLUME) {
    if (cautionThreshold || cautionThreshold === 0) {
      errors.cautionThreshold = validateNumber(cautionThreshold, {maxValue: maxNumber});
    }
    if (warningThreshold || warningThreshold === 0) {
      errors.warningThreshold = validateNumber(warningThreshold, {maxValue: maxNumber});
    }
    if (cautionThreshold && warningThreshold && !errors.cautionThreshold && !errors.warningThreshold &&
      cautionThreshold >= warningThreshold) {
      errors.cautionThreshold = 'Caution Threshold cannot be equal/above Warning Threshold';
    }
  }

  if (target === TargetType.VOLUME && (optionalData || optionalData === 0)) {
    errors.optionalData = validateNumber(optionalData, {maxValue: maxNumberOptional});
  }

  errors.name = validateName(name);

  return errors;
};
