import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {useForm, useFormState} from 'react-final-form';
import {Grid, IconButton} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import {Store} from 'spectra-logic-ui';
import DeleteIcon from 'spectra-logic-ui/icons/Delete';

import {Pool} from '@/types';
import FormSingleSelect from '@/components/form/single_select';
import FormMultiSelect from '@/components/form/multi_select';
import TextField from '@/components/form/text_field';
import Checkbox from '@/components/form/checkbox';
import NoteCard from '@/components/note_card';
import Filters from '@/lifecycle/form/pages/rules/filters';

type Props = {
  ruleField: string;
  index: number;
  onDelete?: () => void;
  storage?: Pool[];
}

const useStyles = makeStyles({
  listItem: {
    listStyleType: 'none',
    zIndex: 99999,
    marginBottom: 15,
  },
  ruleInfo: {
    padding: 10,
  },
  deleteButton: {
    alignSelf: 'flex-end',
    padding: '0',
    margin: '0',
  },
  buttonSize: {
    padding: 5,
    margin: '0 -5px -5px 5px',
  },
  storageSelect: {
    width: '100%',
  },
});

const DestinationsTip = () =>(
  <p>
    Select the destinations to store clones of the object data, in order of
    preference.
    <br/><br/>
    Select a destination count to have the system choose the required number
    of clones from the selected storage destinations. Use "All" to indicate
    every selected storage destination should have a clone of the object data.
    <br/><br/>
    Check the delete option to have the rule delete clone data from any
    storage not included in the selected storage destinations. Any new required
    clones will be created before the deletions.
  </p>
);

const TransitionRule = ({ruleField, index, storage = [], onDelete}: Props) => {
  const classes = useStyles();
  const clonesField = `${ruleField}.destinations.count`;
  const storageField = `${ruleField}.destinations.storage`;
  const formState = useFormState();
  const deletionField = `${ruleField}.deletion`;

  const storageOptions = storage.filter((s) => (
    !s.readOnly
  )).map((s) => ({key: s.id, text: s.name}));

  const clonesOptions = [{key: '0', text: 'All'}];
  // No point displaying "All" and "1" when there's only one storage option
  // to select from.
  if (storageOptions.length > 1) {
    for (let i = 1; i < (storageOptions.length + 1); i++) {
      clonesOptions.push({key: i.toString(), text: i.toString()});
    }
  }

  const form = useForm();
  useEffect(() => {
    const rule = formState.values.rules[index];
    if (rule.destinations === undefined || rule.destinations.count == undefined) {
      form.mutators.setFormValue(clonesField, clonesOptions[0].key);
    }
  }, []);

  return (
    <li className={classes.listItem}>
      <NoteCard title='Placement Rule'>
        <Grid container spacing={2} className={classes.ruleInfo}>
          <Grid item xs={12}>
            <TextField label='Name' name={`${ruleField}.name`} />
          </Grid>
          <Grid item xs={12}>
            <FormMultiSelect className={classes.storageSelect}
              name={storageField}
              label='Select Destination Storage' options={storageOptions}
              sortable
              tooltip={<DestinationsTip />}
            />
          </Grid>
          <Grid item xs={9}>
            <Checkbox name={deletionField} label='Delete clones not on selected destination storage' />
          </Grid>
          <Grid item xs={3}>
            <FormSingleSelect name={clonesField} label='Destination Count'
              options={clonesOptions}
            />
          </Grid>
          <Grid item xs={12}>
            <Filters expiration={false} ruleField={ruleField} />
          </Grid>
          <Grid item xs={1} className={classes.deleteButton}>
            <IconButton className={classes.buttonSize} onClick={onDelete} size='large' title='Delete rule'>
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      </NoteCard>
    </li>
  );
};

const mapStateToProps = (state: Store) => {
  const storageResource = state.resources.storage || {};
  return {
    storage: storageResource.data,
  };
};

export default connect(mapStateToProps)(TransitionRule);
