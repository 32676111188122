import React from 'react';
import {connect} from 'react-redux';
import {FormRenderProps} from 'react-final-form';
import {FormLabel, Grid} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import {createPatch, patchResource} from 'spectra-logic-ui/actions';
import {FormDialog, Tooltip} from 'spectra-logic-ui/components';
import {Dispatch} from 'spectra-logic-ui';

import {EditIAMAccount} from '@/help';
import {Account} from '@/types';
import TextField from '@/components/form/text_field';
import Radio from '@/components/radio';
import {StatusTooltip} from '@/accounts/form/add';
import {FormValues} from '@/accounts/form/types';
import {AccountType} from '@/accounts/form/enum';

type Props = {
  account: Account;
  onSubmit: (values: FormValues) => Promise<any>;
}

const useStyles = makeStyles({
  accountType: {
    display: 'inline-block',
  },
  status: {
    display: 'inline-block',
  },
});

export const AccountPatchFields = [
  'email',
  'description',
  'roleArn',
  'externalId',
  'username',
  'inactive',
];

const EditAccountDialog = ({account, onSubmit, ...otherProps}: Props) => {
  // If the account has a roleArn, it was created by the user and can be edited.
  // An account without a roleArn means it's the default account and should never have a role.
  const cannotEditRole = !account.roleArn;
  const {inactive, ...otherAccount} = account;
  const initialValues = {
    accountType: account.local ? AccountType.LOCAL : AccountType.AWS,
    inactive: inactive ? 'true' : 'false',
    ...otherAccount,
  };
  const classes = useStyles();

  return (
    <FormDialog
      title='Edit IAM Account' initialValues={initialValues} onSubmit={onSubmit}
      getHelpLocation={() => EditIAMAccount}
      {...otherProps}
    >
      {({values}: FormRenderProps) => (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Radio className={classes.accountType}>
              <Radio.Button name='accountType' label='AWS' value={AccountType.AWS} disabled />
              <Radio.Button name='accountType' label='Local' value={AccountType.LOCAL} disabled />
            </Radio>
          </Grid>
          {values.accountType === AccountType.LOCAL &&
            <Grid item xs={12}>
              <TextField name='username' label='Username' />
            </Grid>
          }
          {values.accountType === AccountType.AWS &&
            <>
              <Grid item xs={12}>
                <TextField name='roleArn' label='Role ARN' disabled={cannotEditRole} />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name='externalId'
                  label='External ID'
                  disabled={cannotEditRole}
                  helperText='Optional. Specifies who can assume the role.'
                />
              </Grid>
            </>
          }
          <Grid item xs={12}>
            <TextField name='email' label='Email' />
          </Grid>
          <Grid item xs={12}>
            <TextField name='description' label='Description' />
          </Grid>
          <Grid item xs={12}>
            <FormLabel>Status</FormLabel><Tooltip><StatusTooltip /></Tooltip>
            <Radio className={classes.status}>
              <Radio.Button name='inactive' label='Active' value='false' />
              <Radio.Button name='inactive' label='Inactive' value='true' />
            </Radio>
          </Grid>
        </Grid>
      )}
    </FormDialog>
  );
};

const mapDispatchToProps = (dispatch: Dispatch, {account}: Props) => ({
  onSubmit: (values: FormValues) => {
    const {accountType, ...props} = values;
    delete props.local;
    if (accountType === AccountType.AWS) {
      delete props.username;
    } else {
      delete props.roleArn;
      delete props.externalId;
    }
    props.inactive = props.inactive === 'true';
    const upd = createPatch(AccountPatchFields, account, props);
    return dispatch(patchResource('accounts', values.id, upd));
  },
});

export default connect(undefined, mapDispatchToProps)(EditAccountDialog);
