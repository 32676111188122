import React, {useEffect} from 'react';
import {connect, useDispatch} from 'react-redux';

import {Dispatch, Store} from 'spectra-logic-ui';
import {fetchResource, createResource, resetResource} from 'spectra-logic-ui/actions';
import {WizardFormDialog} from 'spectra-logic-ui/components';

import {FormActionType, TargetType} from '@/enum';
import {AddBlackPearlStorage} from '@/help';
import {Pool, Target} from '@/types';
import OnSiteConfirmPage from '@/storage/form/pages/onsite/confirm';
import OnSiteParametersPage from '@/storage/form/pages/onsite/parameters';
import {validateParameters, validateStorageTarget} from '@/storage/form/pages/validate';
import {items, itemDetails} from '@/storage/form/store';
import StorageTargetPage from '@/storage/form/pages/storage_target';

type Props = {
  endpoint: string;
  onSubmit: (values: Pool) => Promise<any>;
  targets?: Target[];
  targetsFetching?: boolean;
  fetchTargets: Function;
  resetTargets: Function;
  resetItemDetails: Function;
}

const cautionThreshold = 70;
const warningThreshold = 80;
const optionalData = 70;

const AddStorageWizard = ({
  endpoint, onSubmit, targets, targetsFetching=false, fetchTargets, resetTargets, resetItemDetails, ...others
}: Props) => {
  const initialValues = {
    target: '', link: '',
    cautionThreshold: cautionThreshold,
    warningThreshold: warningThreshold,
    optionalData: optionalData,
  };

  const dispatch = useDispatch();
  const fetchTargetItems = (values: any) => {
    const opts = {storeName: items};
    return dispatch(fetchResource(`targets/${endpoint}/${values.target}`, '', opts));
  };

  useEffect(() => {
    fetchTargets();
    return () => {
      resetTargets();
      resetItemDetails();
    };
  }, []);

  return (
    <WizardFormDialog
      title='Add Storage' initialValues={initialValues} onSubmit={onSubmit} width={750}
      getHelpLocation={() => AddBlackPearlStorage}
      {...others}
    >
      <StorageTargetPage title='Storage Type' targets={targets} fetching={targetsFetching}
        validate={validateStorageTarget} onNext={fetchTargetItems} />
      <OnSiteParametersPage title='Parameters' action={FormActionType.CREATE} endpoint={endpoint}
        validate={validateParameters} />
      <OnSiteConfirmPage title='Confirm' />
    </WizardFormDialog>
  );
};

const mapStateToProps = (state: Store, {endpoint}: Props) => {
  const targets = state.resources['targets/' + endpoint] || {};
  return {
    // Do not initialize targets.data to an empty array since we
    // rely on an undefined value to indicate that the targets have
    // not been fetched yet.
    targets: targets.data,
    targetsFetching: targets.fetching,
  };
};

const mapDispatchToProps = (dispatch: Dispatch, {endpoint}: Props) => {
  const targetsStoreName = 'targets/' + endpoint;
  return {
    onSubmit: (values: any) => {
      const {...props} = values;
      props.endpoint = endpoint;
      props.name = props.name.trim();
      if (props.target === TargetType.BPLINK || props.target === TargetType.BLACKPEARL) {
        if (!props.link) {
          props.target = TargetType.BLACKPEARL;
        } else {
          props.target = TargetType.BPLINK;
          delete props.cautionThreshold;
          delete props.warningThreshold;
        }
      }
      if (!(props.target === TargetType.VOLUME || props.target === TargetType.BPVOLUME)) {
        delete props.optionalData;
      }
      return dispatch(createResource('storage', props));
    },
    fetchTargets: () => dispatch(fetchResource(targetsStoreName)),
    resetTargets: () => dispatch(resetResource(targetsStoreName)),
    resetItemDetails: () => dispatch(resetResource(itemDetails)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddStorageWizard);
