import React from 'react';
import {connect} from 'react-redux';
import arrayMutators from 'final-form-arrays';
import isEmpty from 'is-empty';

import {createPatch, fetchResource, patchResource} from 'spectra-logic-ui/actions';
import {WizardFormDialog} from 'spectra-logic-ui/components';
import {Dispatch, Store} from 'spectra-logic-ui';

import {FormActionType} from '@/enum';
import {Filter, LifecycleV2, LifecyclePatchFields, RuleV2} from '@/types';
import {LifecycleForm} from '@/lifecycle/form/enum';
import {FormFilter, FormRule, FormValues} from '@/lifecycle/form/types';
import ParametersPage from '@/lifecycle/form/pages/parameters';
import RulesPage from '@/lifecycle/form/pages/rules';
import {createParametersValidator, validateRules} from '@/lifecycle/form/pages/validate';

type Props = {
  lifecycle: LifecycleV2;
  fetchStorage: () => Promise<any>;
  onSubmit: (body: FormValues) => Promise<any>;
  lifecycles?: LifecycleV2[];
}

const EditLifecycleWizard = ({lifecycle, fetchStorage, onSubmit, lifecycles=[], ...otherProps}: Props) => {
  React.useEffect(() => {
    if (fetchStorage !== undefined) {
      fetchStorage();
    }
  }, []);

  const initialValues = {
    ...lifecycle,
    uploads: lifecycle.uploads ? lifecycle.uploads.toString() : '',
    rules: !lifecycle.rules ? [] : lifecycle.rules.map(({filters, ...rule}: RuleV2) => ({
      ...rule,
      filters: (!filters ? [] : filters.map(({count, ...filter}: Filter) => ({
        ...filter,
        count: (count !== undefined && count !== null) ? count.toString() : count,
      }))),
    })),
  } as FormValues;
  const parametersValidator = createParametersValidator(lifecycles?.filter((l) => l.name !== lifecycle.name));

  return (
    <WizardFormDialog
      title={LifecycleForm.EDIT} initialValues={initialValues}
      mutators={{...arrayMutators}} onSubmit={onSubmit} {...otherProps}
    >
      <ParametersPage title='Parameters' action={FormActionType.EDIT} validate={parametersValidator} />
      <RulesPage title='Rules' validate={validateRules} />
    </WizardFormDialog>
  );
};

const mapStateToProps = (state: Store) => ({
  lifecycles: (state.resources.lifecyclesV2 || {}).data,
});

const mapDispatchToProps = (dispatch: Dispatch, {lifecycle}: Props) => ({
  fetchStorage: () => dispatch(fetchResource('storage')),
  onSubmit: (values: FormValues) => {
    const body = {
      ...values,
      uploads: Number(values.uploads),
      name: values.name.trim(),
      rules: !values.rules ? [] : values.rules.map((rule: FormRule) => ({
        ...rule,
        name: rule.name ? rule.name.trim() : rule.name,
        destinations: !isEmpty(rule.destinations) ?
          {...rule.destinations, count: Number(rule.destinations.count)} : undefined,
        filters: !rule.filters ? [] : rule.filters.map(({count, ...filter}: FormFilter) => ({
          ...filter,
          count: !isEmpty(count) ? Number(count) : undefined,
        })),
        // noncurrentVersions: !isEmpty(rule.noncurrentVersions) ? Number(rule.noncurrentVersions) : undefined,
      })),
    } as LifecycleV2;
    const upd = createPatch(LifecyclePatchFields, lifecycle, body);
    return dispatch(patchResource('lifecyclesv2', body.id, upd));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EditLifecycleWizard);
