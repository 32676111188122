import React from 'react';
import {connect} from 'react-redux';
import {useFormState} from 'react-final-form';

import makeStyles from '@mui/styles/makeStyles';

import {fetchResource} from 'spectra-logic-ui/actions';
import {WizardPage} from 'spectra-logic-ui/components';
import {Dispatch, Store} from 'spectra-logic-ui';

import {LifecycleV2} from '@/types';
import FormSingleSelect from '@/components/form/single_select';

type PageProps = {
  title: string;
  lifecycles?: LifecycleV2[];
  fetching?: boolean;
  error?: boolean;
  form?: string;
  fetchLifecycles?: () => Promise<any>;
}

const useStyles = makeStyles({
  singleSelect: {
    width: '40%',
  },
});

const LifecyclePage = ({title, lifecycles = [], fetchLifecycles, ...others}: PageProps) => {
  const classes = useStyles();
  const formState = useFormState();
  const linkedStorage = formState.values.linkedStorage;
  const filteredLifecycles = lifecycles.filter((l) => (!l.linkedStorage || l.linkedStorage == linkedStorage));

  React.useEffect(() => {
    if (fetchLifecycles !== undefined) {
      fetchLifecycles();
    }
  }, []);

  return (
    <WizardPage title={title} {...others}>
      <div className={classes.singleSelect}>
        <FormSingleSelect
          name='lifecycle' label='Select Lifecycle' noneOption={true}
          options={filteredLifecycles.map((l) => ({key: l.id, text: l.name}))}
        />
      </div>
    </WizardPage>
  );
};

const mapStateToProps = (state: Store) => {
  const lifecyclesResource = state.resources.lifecyclesv2 || {};
  return {
    lifecycles: lifecyclesResource.data || [],
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchLifecycles: () => dispatch(fetchResource('lifecyclesv2')),
});

export default connect(mapStateToProps, mapDispatchToProps)(LifecyclePage);
