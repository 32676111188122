import {
  BucketOwnershipControls,
  EndpointType,
  FilterType,
  NodeStatus,
  StorageClass,
  TargetType,
  UpdateStatusUpdate,
} from '@/enum';

declare global {
  interface Window {
    SPECTRA_LOGIC_VAIL_INITIAL_STATE: {
      authEnabled: boolean;
      pathPrefix: string;
      os: string;
      name: string;
      single: boolean;
    };
  }
}

/*
  API object types
 */

export interface Account {
  id: string;
  canonicalId: string;
  externalId: string;
  roleArn: string;
  username: string;
  email: string;
  description?: string;
  default?: boolean;
  local?: boolean;
  inactive?: boolean;
}

export interface Administrator {
  id: string;
  username: string;
  email: string;
  info: boolean;
  warning: boolean;
  error: boolean;
}

export interface RuleSchedule {
  days: number;
}

export interface Destinations {
  count?: number;
  storage: string[];
}

export interface Filter {
  filter: FilterType;
  key?: string;
  value?: string;
  exclude?: boolean;
  count?: number;
}

export interface RuleV2 {
  name: string;
  deletion?: boolean;
  expiration?: boolean;
  destinations?: Destinations;
  filters?: Filter[];
}

export interface LifecycleV2 {
  id: string;
  name: string;
  uploads?: number;
  markers?: boolean;
  afterPut?: boolean;
  ignoreClass?: boolean;
  modified: Date;
  rules?: RuleV2[];
  description?: string;
  linkedStorage?: string;
}
export const LifecyclePatchFields = [
  'name',
  'uploads',
  'markers',
  'afterPut',
  'ignoreClass',
  'rules',
  'description',
];

export interface Acl {
  id: string;
  type: string;
  read?: boolean;
  readACP?: boolean;
  write?: boolean;
  writeACP?: boolean;
}

export interface Bucket {
  name: string;
  created: string;
  owner: string;
  versioning?: string;
  locking?: boolean;
  encrypt?: boolean;
  compress?: boolean;
  restore?: boolean;
  ruleScan: boolean;
  acls: Acl[];
  policy?: object;
  lifecycle?: string;
  linkedStorage?: string;
  unlink: boolean;
  control: BucketOwnershipControls;
  blockPublicAcls: boolean;
  blockPublicPolicy: boolean;
  ignorePublicAcls: boolean;
  restrictPublicBuckets: boolean;
  defaultRetention?: DefaultRetention;
}

export interface DefaultRetention {
  compliance?: boolean;
  days?: number;
  years?: number;
}

export interface Pool {
  id: string;
  name: string;
  endpoint: string;
  storageClass?: StorageClass;
  target: TargetType;
  item: string;
  link?: string;
  pauseNotifications?: boolean;
  size?: number;
  used?: number;
  cautionThreshold?: number;
  warningThreshold?: number;
  optionalData?: number;
  status?: string;
  archival?: boolean;
  readOnly?: boolean;
  empty?: boolean;
  cloneRestore?: boolean;
  recoverable?: boolean;
  verificationRunning?: boolean;
}

export interface Target {
  id: string;
  name: string;
  tip?: string;
}

export interface TargetItem {
  id: string;
  name?: string;
}

export interface TargetItemDetails {
  target: string;
  item: string;
  name?: string;
  link?: boolean;
  optional?: boolean;
  threshold?: boolean;
  classes?: StorageClass[];
  properties?: object;
}

export const PoolPatchFields = [
  'name',
  'storageClass',
  'cloneRestore',
  'pauseNotifications',
  'recoverable',
  'cautionThreshold',
  'warningThreshold',
  'optionalData',
];

export interface StorageEntity {
  id: string;
  storageClass: string;
  data?: number;
  optional?: number;
}
export interface StorageUsed {
  label?: string;
  used?: number;
  data?: number;
  optional?: number;
  total?: number;
  endpoint?: string;
  location?: string;
  storage: StorageEntity[];
  entity?: string;
}

export interface Location {
  id: string;
  name: string;
  longitude?: number;
  latitude?: number;
  status?: string;
}

export const LocationPatchFields = [
  'name',
  'latitude',
  'longitude',
];

export interface LocationCapacityMap {
  [id: string]: StorageUsed[];
}

export interface Pod {
  id: string;
  type: EndpointType;
  name: string;
  location: string;
  status?: NodeStatus;
  version: string;
  url: string;
  // Only present on BlackPearls and when making a 'GET /sl/api/system' request
  managementURL?: string;
  hosts?: string[];
  debug?: number;
  staticURL?: boolean;
}

export const PodPatchFields = [
  'location',
  'debug',
  'hosts',
  'url',
];

export interface BlackPearlStatus {
  id: string;
  name: string;
  serialNumber: string;
  cacheUsed: number;
  cacheTotal: number;
  databaseUsed: number;
  databaseTotal: number;
  buckets: number;
  activeJobs: number;
  capacitySummary: CapacitySummary[];
}

export interface BPProperties {
  policy?: string;
  policyName?: string;
  partitions?: string[];
}

export interface CloudProperties {
  owner: string;
  ownerName: string;
  region?: string;
  endpoint?: string;
  unsafe?: boolean;
  virtualHost?: boolean;
}

export interface CapacitySummary {
  type: string;
  allocated: number;
  total: number;
  Used: number;
}

export interface NetworkInterface {
  name: string;
  addresses: string[];
  mtu: Number;
  dhcp4?: boolean;
  auto6?: boolean;
  gateway4?: string;
  gateway6?: string;
  nameServers?: string[];
  searchDomains?: string[];
  dhcpDns?: boolean;
}
export const NetworkInterfacePatchFields = [
  'mtu',
  'addresses',
  'dhcp4',
  'auto6',
  'gateway4',
  'gateway6',
  'nameServers',
  'searchDomains',
  'dhcpDns',
];

export interface Proxy {
  id?: string;
  hostname?: string;
  port?: number;
  username?: string;
  password?: string;
}

export interface Entitlement {
  dimension: string;
  value: any;
  expires?: string;
}

export interface SSLCertificate {
  issuer: string;
  subject: string;
  notBefore: string;
  notAfter: string;

  certPEM: string;
  privateKeyPEM: string;
  passphrase: string;
}

export interface LogSet {
  id: string;
  creating: boolean;
  created: string;
  type: string;
  size: number;
}

export type SSLCertValues = {
  issuer: string;
  subject: string;
  notBefore: string;
  notAfter: string;

  certPEM: string;
  privateKeyPEM: string;
  passphrase: string;
}

export type InterfaceValues = {
  name: string;
  addresses: string[];
  mtu: Number;
  hardwareAddress: string;
  dhcp4?: boolean;
  auto6?: boolean;
  gateway4?: string;
  gateway6?: string;
  linkStatus: string;
  dhcpDnsOption: string;
  nameServers: string;
  searchDomains: string;
}

export type Token = {
  token: string;
  challengeName: string;
  challengeParameters: TokenChallengeParameters;
  session: string;
  username: string;
  // Sphere is only used for "remote" sphere tokens (e.g. the one used during
  // node registration).
  sphere: string;
}

export type TokenChallengeParameters = {
  USER_ID_FOR_SRP: string;
}

export type Sphere = {
  version: string;
  status?: NodeStatus;
};

export type EndpointUpdateVersion = {
  id: string;
  version: string;
}

export type PerformanceTable = {
  tables: string[];
  type: string;
  title: string;
  description: string;
}

export type Metrics = {
  count?: number;
  size?: number;
  stored?: number;
  optional?: number;
}

export type UpdateStatus = {
  update: UpdateStatusUpdate;
  detail?: string;
  value?: number;
}
