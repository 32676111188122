import React from 'react';
import {FieldArray} from 'react-final-form-arrays';
import {Grid, IconButton, InputAdornment} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import {SplitButton} from 'spectra-logic-ui/components';
import DeleteIcon from 'spectra-logic-ui/icons/Delete';

import {FilterType} from '@/enum';
import {Filter} from '@/types';
import TextField from '@/components/form/text_field';
import {FormFilter} from '@/lifecycle/form/types';

enum SpecificFilterType {
  SCHEDULE = 'Schedule',
  LATEST_VERSION = 'Latest Version',
  PREVIOUS_VERSION = 'Previous Version',
  INCLUDE_NAME = 'Include Name',
  EXCLUDE_NAME = 'Exclude Name',
  INCLUDE_TAG = 'Include Object Tag',
  EXCLUDE_TAG = 'Exclude Object Tag',
}

const filters = [
  SpecificFilterType.SCHEDULE,
  SpecificFilterType.LATEST_VERSION,
  SpecificFilterType.PREVIOUS_VERSION,
  SpecificFilterType.INCLUDE_NAME,
  SpecificFilterType.EXCLUDE_NAME,
  SpecificFilterType.INCLUDE_TAG,
  SpecificFilterType.EXCLUDE_TAG,
];

const defaultFilterFields: Filter[] = [
  {filter: FilterType.SCHEDULE},
  {filter: FilterType.CURRENT},
  {filter: FilterType.CURRENT, exclude: true},
  {filter: FilterType.NAME},
  {filter: FilterType.NAME, exclude: true},
  {filter: FilterType.TAG},
  {filter: FilterType.TAG, exclude: true},
];

const allFilterOptions = filters.map((f) => ('Add ' + f + ' Filter'));

const defaultFilterFieldMap: Record<string, Filter> = {};
defaultFilterFields.forEach((f, i) => defaultFilterFieldMap[allFilterOptions[i]] = f);

const maxFilters = 10;

const useStyles = makeStyles({
  filter: {
    padding: '10px',
    margin: '5px 0 5px 0',
    backgroundColor: '#eee',
    borderRadius: '5px',
  },
});

const AfterTip = `
The rule applies only to objects older than the number of days specified. Processing occurs
at the daily processing time. A value of 0 applies the rule on the next daily processing,
which will be between 0 and 24 hours later. If no schedule filter is added, the rule applies
immediately, without waiting for daily processing.
`;

const NameIncludeTip = () => (
  <p>
    The rule applies only to objects with a name that matches the provided
    regular expression. If multiple include name filters are defined, the
    rule applies if any of them match the object name.
    <br/><br/>
    <strong>Examples</strong><br/>
    Prefix: archive/images/production/:<br/>&nbsp;&nbsp;&nbsp;&nbsp;<i>^archive/images/production/</i>
    <br/>
    Objects ending in .txt:<br/>&nbsp;&nbsp;&nbsp;&nbsp;<i>\.txt$</i>
  </p>
);

const NameExcludeTip = () => (
  <span>
    The rule applies only to objects with a name that doesn't match the provided
    regular expression.
    <br/><br/>
    <strong>Examples</strong><br/>
    Prefix: archive/images/production/:<br/>&nbsp;&nbsp;&nbsp;&nbsp;<i>^archive/images/production/</i>
    <br/>
    Objects ending in .txt:<br/>&nbsp;&nbsp;&nbsp;&nbsp;<i>\.txt$</i>
  </span>
);

const TagIncludeTip = () => (
  <p>
    The rule applies to objects with a matching object tag. Exact case-sensitive matches are used for both the key and
    value. If no value is specified, the rule applies to all objects that have a matching tag key of any value. If
    multiple include tag filters are defined, the rule applies if any of them match an object tag.
  </p>
);

const TagExcludeTip = () => (
  <p>
    The rule applies only to objects that don't have a matching object tag. Exact case-sensitive matches are used for
    both the key and value. If no value is specified, the rule will not apply to any object that has a matching tag key
    of any value.
  </p>
);

const NCVsTip = () => (
  <p>
    The number of non-current versions that should be kept and not expired.
    After this limit is reached, any excess non-current versions will be allowed to
    expire based on the schedule filter that is set (starting with the oldest non-current
    versions). If for example this value is set to "1", then the most recent version of
    an object (the current version) will be kept along with the single most recent
    previous version of that object (the newest non-current version).
  </p>
);

type Props = {
  expiration: boolean;
  ruleField: string;
}

const Filters = ({expiration, ruleField}: Props) => {
  return (
    <FieldArray name={`${ruleField}.filters`}>
      {({fields}) => {
        const addedFilters: FormFilter[] = fields.value || [];
        // There can be only one schedule filter.
        const hasSchedule = addedFilters.some((f: FormFilter) => f.filter === FilterType.SCHEDULE);
        // There can be only one version filter.
        const hasVersion = addedFilters.some((f: FormFilter) => f.filter === FilterType.CURRENT);
        const availFilterTypes: SpecificFilterType[] = [];
        const availFilterOpts: string[] = [];
        filters.forEach((f, i) => {
          if (f === SpecificFilterType.SCHEDULE && hasSchedule) {
            return;
          }
          if ((f === SpecificFilterType.LATEST_VERSION || f === SpecificFilterType.PREVIOUS_VERSION) &&
            hasVersion) {
            return;
          }
          availFilterTypes.push(f);
          availFilterOpts.push(allFilterOptions[i]);
        });

        const handleNewFilter = (_e: React.MouseEvent<HTMLButtonElement, MouseEvent>, i: number) => {
          fields.push(defaultFilterFieldMap[availFilterOpts[i]]);
        };

        const handleFilterDelete = (i: number) => fields.remove(i);

        return (
          <div>
            {fields.map((value: string, index: number) => {
              const filter = fields.value[index] as FormFilter;
              return (
                <FilterForm
                  key={index} expiration={expiration} filter={filter} filterField={value}
                  onDelete={() => handleFilterDelete(index)}
                />
              );
            })}
            {(fields.length || 0) < maxFilters &&
              <SplitButton options={availFilterOpts} onClick={handleNewFilter} />
            }
          </div>
        );
      }}
    </FieldArray>
  );
};
export default Filters;

type FilterFormProps = {
  expiration: boolean;
  filter: FormFilter;
  filterField: string;
  onDelete: () => void;
}

export const FilterForm = ({expiration, filter, filterField, onDelete}: FilterFormProps) => {
  const classes = useStyles();
  let title;
  switch (filter.filter) {
  case FilterType.SCHEDULE:
    title = SpecificFilterType.SCHEDULE;
    break;
  case FilterType.CURRENT:
    title = filter.exclude ? SpecificFilterType.PREVIOUS_VERSION : SpecificFilterType.LATEST_VERSION;
    break;
  case FilterType.NAME:
    title = filter.exclude ? SpecificFilterType.EXCLUDE_NAME : SpecificFilterType.INCLUDE_NAME;
    break;
  case FilterType.TAG:
    title = filter.exclude ? SpecificFilterType.EXCLUDE_TAG : SpecificFilterType.INCLUDE_TAG;
    break;
  }
  title = `${title} Filter`;

  const countField = `${filterField}.count`;

  const NameTip = expiration ? NameExcludeTip : NameIncludeTip;
  const TagTip = expiration ? TagExcludeTip : TagIncludeTip;

  return (
    <div className={classes.filter}>
      <p>{title}</p>
      <Grid container spacing={2}>
        {filter.filter === FilterType.SCHEDULE &&
          <Grid item xs={11}>
            <TextField
              label='Older Than' name={countField}
              InputProps={{endAdornment: <InputAdornment position='start'>days</InputAdornment>}}
              tooltip={AfterTip}
              parse={(value) => {
                return value === '' ? undefined : value;
              }}
            />
          </Grid>
        }
        <Grid item xs={11}>
          {filter.filter === FilterType.CURRENT && expiration && filter.exclude &&
            <TextField
              label='Versions' name={countField}
              InputProps={{endAdornment: <InputAdornment position='start'>count</InputAdornment>}}
              tooltip={<NCVsTip />}
              parse={(value) => {
                return value === '' ? undefined : value;
              }}
            />
          }
        </Grid>
        {filter.filter === FilterType.NAME &&
          <Grid item xs={11}>
            <TextField label='Match' name={`${filterField}.value`} tooltip={<NameTip />} />
          </Grid>
        }
        {filter.filter === FilterType.TAG &&
          <>
            <Grid item xs={6}>
              <TextField label='Key' name={`${filterField}.key`} />
            </Grid>
            <Grid item xs={5}>
              <TextField label='Value' name={`${filterField}.value`} tooltip={<TagTip />} />
            </Grid>
          </>
        }
        <Grid item xs={1}>
          <IconButton onClick={onDelete} size='large' title='Delete filter'>
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
};
