import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import isEmpty from 'is-empty';

import LifecycleIcon from 'spectra-logic-ui/icons/Autorenew';
import {Card, SlidePanel, Table} from 'spectra-logic-ui/components';
import {dateTimeLong} from 'spectra-logic-ui/helpers/date';
import {fetchResource} from 'spectra-logic-ui/actions';
import {Dispatch, Store} from 'spectra-logic-ui';

import {LifecycleV2} from '@/types';
import ButtonToolbar from '@/components/button_toolbar';
import CardHeader from '@/components/card_header';
import DeleteDialog from '@/components/delete_dialog';
import PropertiesDetails from '@/lifecycle/details/properties';
import RulesDetails from '@/lifecycle/details/rules';
import CreateLifecycleWizard from '@/lifecycle/form/create';
import EditLifecycleWizard from '@/lifecycle/form/edit';

type PageProps = {
  lifecycles: LifecycleV2[];
  fetching: boolean;
  error: boolean;
  fetchLifecycles: () => Promise<any>;
}

const Lifecycles = ({lifecycles, fetching, error, fetchLifecycles}: PageProps) => {
  const [selectedLifecycleId, setSelectedLifecycleId] = useState('');
  const [isOpenDetails, setOpenDetails] = useState(false);

  useEffect(() => {
    if (fetchLifecycles !== undefined) {
      fetchLifecycles();
    }
  }, []);

  useEffect(() => {
    if (selectedLifecycleId && !lifecycles.find((lifecycle) => lifecycle.id === selectedLifecycleId)) {
      clearLifecycle();
    }
  }, [lifecycles]);

  const closeDetails = () => setOpenDetails(false);
  const clearLifecycle = () => {
    setSelectedLifecycleId('');
    setOpenDetails(false);
  };

  const selectedLifecycle = lifecycles.find((l) => l.id === selectedLifecycleId) || {} as LifecycleV2;

  return (
    <React.Fragment>
      <Card>
        <CardHeader icon={LifecycleIcon} tooltip='Lifecycles created are available for use when creating buckets.'>
          Lifecycles
        </CardHeader>
        <Card.Body>
          <ButtonToolbar>
            <ButtonToolbar.CreateDialogButton dialog={CreateLifecycleWizard} onClick={clearLifecycle} />
            <ButtonToolbar.EditDialogButton
              dialog={(props: any) => <EditLifecycleWizard lifecycle={selectedLifecycle} {...props} />}
              onClick={closeDetails} onSuccess={clearLifecycle} disabled={isEmpty(selectedLifecycleId)}
            />
            <ButtonToolbar.DeleteDialogButton
              onClick={closeDetails} onSuccess={clearLifecycle}
              disabled={isEmpty(selectedLifecycleId)}
              dialog={(props: any) => (
                <DeleteDialog
                  id={selectedLifecycleId} name={selectedLifecycle.name} resource='lifecyclesv2' {...props}
                />
              )}
            />
          </ButtonToolbar>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.Cell>Lifecycle Name</Table.Cell>
                <Table.Cell>Modified</Table.Cell>
                <Table.Cell>Number of Rules</Table.Cell>
                <Table.Cell> </Table.Cell>
              </Table.Row>
            </Table.Header>
            <Table.Body isLoading={fetching} hasError={error}>
              {lifecycles && lifecycles.map((lifecycle) => (
                <Table.Row
                  key={lifecycle.id} selected={lifecycle.id === selectedLifecycleId}
                  onClick={() => setSelectedLifecycleId(lifecycle.id)}
                >
                  <Table.Cell>{lifecycle.name}</Table.Cell>
                  <Table.Cell>{dateTimeLong(lifecycle.modified)}</Table.Cell>
                  <Table.Cell>{!lifecycle.rules ? 0 : lifecycle.rules.length}</Table.Cell>
                  <Table.CellDetailsButton onClick={() => setOpenDetails(true)} />
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Card.Body>
      </Card>
      <SlidePanel
        title={selectedLifecycle.name} options={['Properties', 'Rules']}
        open={isOpenDetails} onClose={closeDetails}
      >
        <PropertiesDetails lifecycle={selectedLifecycle} />
        <RulesDetails lifecycle={selectedLifecycle} />
      </SlidePanel>
    </React.Fragment>
  );
};

const mapStateToProps = (state: Store) => {
  const lifecycles = state.resources.lifecyclesv2 || {};
  return {
    lifecycles: lifecycles.data || [],
    fetching: lifecycles.fetching,
    error: lifecycles.error,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchLifecycles: () => dispatch(fetchResource('lifecyclesv2')),
});

export default connect(mapStateToProps, mapDispatchToProps)(Lifecycles);
