import React from 'react';
import {connect} from 'react-redux';
import {FormRenderProps} from 'react-final-form';
import {FormLabel, Grid, Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import {createResource} from 'spectra-logic-ui/actions';
import {FormDialog, Tooltip} from 'spectra-logic-ui/components';
import {Dispatch} from 'spectra-logic-ui';

import {AddIAMAccount} from '@/help';
import Radio from '@/components/radio';
import TextField from '@/components/form/text_field';
import {FormValues} from '@/accounts/form/types';
import {AccountType} from '@/accounts/form/enum';

type Props = {
  onSubmit: (values: FormValues) => Promise<any>;
}

const useStyles = makeStyles({
  accountType: {
    display: 'inline-block',
  },
  status: {
    display: 'inline-block',
  },
});

export const StatusTooltip = () => {
  return (
    <Typography variant='body2' display='inline'>
      Account status controls the status of all access keys associated with all IAM users within the account.
      Inactive accounts will result in all access keys also being inactive, thus preventing those IAM users
      from accessing the system. Access keys cannot be activated while an account is inactive.
    </Typography>
  );
};

const CreateAccountDialog = ({onSubmit, ...otherProps}: Props) => {
  const initialValues = {accountType: AccountType.AWS, inactive: 'false'};
  const classes = useStyles();

  return (
    <FormDialog
      title='Add IAM Account' getHelpLocation={() => AddIAMAccount} onSubmit={onSubmit} initialValues={initialValues}
      {...otherProps}
    >
      {({values}: FormRenderProps) => (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Radio className={classes.accountType}>
              <Radio.Button name='accountType' label='AWS' value={AccountType.AWS} />
              <Radio.Button name='accountType' label='Local' value={AccountType.LOCAL} />
            </Radio>
          </Grid>
          {values.accountType === AccountType.LOCAL &&
            <Grid item xs={12}>
              <TextField name='username' label='Username' />
            </Grid>
          }
          {values.accountType === AccountType.AWS &&
            <>
              <Grid item xs={12}>
                <TextField name='roleArn' label='Role ARN' />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name='externalId'
                  label='External ID'
                  helperText='Optional. Specifies who can assume the role.'
                />
              </Grid>
            </>
          }
          <Grid item xs={12}>
            <TextField name='email' label='Email' />
          </Grid>
          <Grid item xs={12}>
            <TextField name='description' label='Description' />
          </Grid>
          <Grid item xs={12}>
            <FormLabel>Status</FormLabel><Tooltip><StatusTooltip /></Tooltip>
            <Radio className={classes.status}>
              <Radio.Button name='inactive' label='Active' value='false' />
              <Radio.Button name='inactive' label='Inactive' value='true' />
            </Radio>
          </Grid>
        </Grid>
      )}
    </FormDialog>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSubmit: (values: FormValues) => {
    const {accountType, ...props} = values;
    if (accountType === AccountType.AWS) {
      props.local = false;
      delete props.username;
    } else {
      props.local = true;
      delete props.roleArn;
      delete props.externalId;
    }
    props.inactive = props.inactive === 'true';
    return dispatch(createResource('accounts', props));
  },
});

export default connect(undefined, mapDispatchToProps)(CreateAccountDialog);
