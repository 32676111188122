import React from 'react';
import {connect} from 'react-redux';
import arrayMutators from 'final-form-arrays';
import isEmpty from 'is-empty';

import {createResource, fetchResource} from 'spectra-logic-ui/actions';
import {WizardFormDialog} from 'spectra-logic-ui/components';
import {Dispatch, Store} from 'spectra-logic-ui';

import {FormActionType} from '@/enum';
import {CreateLifecycle} from '@/help';
import {LifecycleV2} from '@/types';
import {LifecycleForm} from '@/lifecycle/form/enum';
import {FormFilter, FormRule, FormValues} from '@/lifecycle/form/types';
import ParametersPage from '@/lifecycle/form/pages/parameters';
import RulesPage from '@/lifecycle/form/pages/rules';
import {createParametersValidator, validateRules} from '@/lifecycle/form/pages/validate';

type PageProps = {
  fetchStorage: () => Promise<any>;
  onSubmit: (body: FormValues) => Promise<any>;
  lifecycles?: LifecycleV2[];
}

const CreateLifecycleWizard = ({fetchStorage, onSubmit, lifecycles=[], ...otherProps}: PageProps) => {
  React.useEffect(() => {
    if (fetchStorage !== undefined) {
      fetchStorage();
    }
  }, []);

  const initialValues = {
    uploads: '7',
    markers: true,
  };
  const parametersValidator = createParametersValidator(lifecycles);

  return (
    <WizardFormDialog
      title={LifecycleForm.CREATE} initialValues={initialValues}
      getHelpLocation={() => CreateLifecycle}
      mutators={{...arrayMutators}} onSubmit={onSubmit} {...otherProps}
    >
      <ParametersPage title='Parameters' action={FormActionType.CREATE} validate={parametersValidator} />
      <RulesPage title='Rules' validate={validateRules} />
    </WizardFormDialog>
  );
};

const mapStateToProps = (state: Store) => ({
  lifecycles: (state.resources.lifecyclesv2 || {}).data,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchStorage: () => dispatch(fetchResource('storage')),
  onSubmit: (values: FormValues) => {
    const body = {
      ...values,
      uploads: Number(values.uploads),
      name: values.name.trim(),
      rules: !values.rules ? [] : values.rules.map((rule: FormRule) => ({
        ...rule,
        name: rule.name ? rule.name.trim() : rule.name,
        destinations: !isEmpty(rule.destinations) ?
          {...rule.destinations, count: Number(rule.destinations.count)} : undefined,
        filters: !rule.filters ? [] : rule.filters.map(({count, ...filter}: FormFilter) => ({
          ...filter,
          count: !isEmpty(count) ? Number(count) : undefined,
        })),
        // noncurrentVersions: !isEmpty(rule.noncurrentVersions) ? Number(rule.noncurrentVersions) : undefined,
      })),
    } as LifecycleV2;
    return dispatch(createResource('lifecyclesv2', body));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateLifecycleWizard);
