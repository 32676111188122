import React, {useEffect} from 'react';
import {connect, useDispatch} from 'react-redux';

import {Dispatch, Store} from 'spectra-logic-ui';
import {createCollection, createResource, fetchResource, resetResource} from 'spectra-logic-ui/actions';
import {WizardFormDialog} from 'spectra-logic-ui/components';

import {FormActionType} from '@/enum';
import {AddCloudStorage} from '@/help';
import {Pool, Target} from '@/types';
import CloudAuthorizationPage from '@/storage/form/pages/cloud/authorization';
import CloudConfirmPage from '@/storage/form/pages/cloud/confirm';
import CloudParametersPage, {getCloudBucketsBody} from '@/storage/form/pages/cloud/parameters';
import StorageTargetPage from '@/storage/form/pages/storage_target';
import {validateStorageTarget, validateAuthorization, validateParameters} from '@/storage/form/pages/validate';
import {items, itemDetails} from '@/storage/form/store';

type Props = {
  onSubmit: (values: Pool) => Promise<any>;
  targets?: Target[];
  targetsFetching?: boolean;
  fetchTargets: Function;
  resetTargets: Function;
  resetItemDetails: Function;
  cautionThreshold: number;
  warningThreshold: number;
  optionalData: number;
}

const AddStorageWizard = ({
  onSubmit, targets, targetsFetching=false, fetchTargets, resetTargets, resetItemDetails, ...others
}: Props) => {
  // Radio buttons are used for virtualHost and thus requires a string value.
  const initialValues = {target: '', link: '', virtualHost: 'false'};

  const dispatch = useDispatch();

  const fetchTargetItems = (values: any) => {
    const body = getCloudBucketsBody(values);
    const opts = {storeName: items};
    return dispatch(createCollection(`targets/0/${values.target}`, body, opts));
  };

  useEffect(() => {
    fetchTargets();
    return () => {
      resetTargets();
      resetItemDetails();
    };
  }, []);

  return (
    <WizardFormDialog
      title='Add Cloud Storage' initialValues={initialValues}
      onSubmit={onSubmit} width={750}
      getHelpLocation={() => AddCloudStorage}
      {...others}
    >
      <StorageTargetPage title='Storage Type' targets={targets} fetching={targetsFetching}
        validate={validateStorageTarget} />
      <CloudAuthorizationPage title='Authorization' action={FormActionType.CREATE} validate={validateAuthorization}
        onNext={fetchTargetItems} />
      <CloudParametersPage title='Parameters' action={FormActionType.CREATE} validate={validateParameters} />
      <CloudConfirmPage title='Confirm' action={FormActionType.CREATE} />
    </WizardFormDialog>
  );
};

const cloudTargetsStoreName = 'targets/0';

const mapStateToProps = (state: Store) => {
  const targets = state.resources[cloudTargetsStoreName] || {};
  return {
    // Do not initialize targets.data to an empty array since we
    // rely on an undefined value to indicate that the targets have
    // not been fetched yet.
    targets: targets.data,
    targetsFetching: targets.fetching,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onSubmit: (values: any) => {
      const {...props} = values;
      if (!props.link) {
        // Notification pausing is only applicable to linked buckets.
        delete props.pauseNotifications;
      }
      const params = getCloudBucketsBody(props);
      delete props.accessKey;
      delete props.secretKey;
      delete props.credentials;
      delete props.account;
      delete props.secret;
      delete props.region;
      delete props.url;
      delete props.arn;
      delete props.externalid;
      props.parameters = params;
      return dispatch(createResource('storage', props));
    },
    fetchTargets: () => dispatch(fetchResource(cloudTargetsStoreName)),
    resetTargets: () => dispatch(resetResource(cloudTargetsStoreName)),
    resetItemDetails: () => dispatch(resetResource(itemDetails)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddStorageWizard);
