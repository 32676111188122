import React from 'react';
import Grid from '@mui/material/Grid';

import {SlidePanel} from 'spectra-logic-ui/components';

import {IAMUser, IAMGroup} from '@/iam/types';
import IAMGroups from '@/iam/groups';
import IAMUsers from '@/iam/users';
import IAMUserGroups from '@/iam/users/details/groups';
import UserProperties from '@/iam/users/details/properties';
import GroupProperties from '@/iam/groups/details/properties';
import GroupUsers from '@/iam/groups/details/users';
import AccessKeys from '@/iam/users/details/keys';

const Users = () => {
  const [selectedUser, setSelectedUser] = React.useState({} as IAMUser);
  const [selectedGroup, setSelectedGroup] = React.useState({} as IAMGroup);
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const openDrawer = () => setIsDrawerOpen(true);
  const closeDrawer = () => setIsDrawerOpen(false);
  const selectIAMUser = (user: IAMUser) => {
    setSelectedUser(user);
    setSelectedGroup({} as IAMGroup);
  };
  const selectIAMGroup = (group: IAMGroup) => {
    setSelectedUser({} as IAMUser);
    setSelectedGroup(group);
  };

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12}>
          <IAMUsers
            selectedUser={selectedUser} selectUser={selectIAMUser} openDrawer={openDrawer} closeDrawer={closeDrawer}
          />
        </Grid>
        <Grid item xs={12}>
          <IAMGroups
            selectedGroup={selectedGroup} selectGroup={selectIAMGroup} openDrawer={openDrawer} closeDrawer={closeDrawer}
          />
        </Grid>
      </Grid>
      <Panel
        selectedUser={selectedUser} selectedGroup={selectedGroup} isDrawerOpen={isDrawerOpen} closeDrawer={closeDrawer}
      />
    </React.Fragment>
  );
};

type PanelProps = {
  selectedUser: IAMUser;
  selectedGroup: IAMGroup;
  isDrawerOpen: boolean;
  closeDrawer: (...args: any) => any;
};

const Panel = ({selectedUser, selectedGroup, isDrawerOpen, closeDrawer}: PanelProps) => {
  const title = selectedUser.username ? selectedUser.username : selectedGroup.name;
  const options = selectedUser.username ? ['Properties', 'IAM Groups', 'Access Keys'] : ['Properties', 'Users'];
  const tabs = selectedUser.username ?
    [
      <UserProperties key={1} user={selectedUser} />,
      <IAMUserGroups key={2} user={selectedUser} />,
      <AccessKeys key={3} user={selectedUser} />,
    ] : [<GroupProperties key={4} group={selectedGroup} />, <GroupUsers key={5} group={selectedGroup} />];

  return (
    <SlidePanel title={title} options={options} open={isDrawerOpen} onClose={closeDrawer}>
      {tabs}
    </SlidePanel>
  );
};

export default Users;
