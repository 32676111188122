import React, {useEffect} from 'react';
import {connect} from 'react-redux';

import {Dispatch, Store} from 'spectra-logic-ui';
import {fetchResource} from 'spectra-logic-ui/actions';
import {Table} from 'spectra-logic-ui/components';

import {IAMGroup, IAMUser} from '@/iam/types';

type Props = {
  group: IAMGroup;
  users?: IAMUser[];
  fetching?: boolean;
  error?: boolean;
  fetchUsers?: () => Promise<any>;
}

const Users = ({users=[], fetching=false, error=false, fetchUsers}: Props) => {
  useEffect(() => {
    if (fetchUsers !== undefined) {
      fetchUsers();
    }
  }, []);

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.Cell>Username</Table.Cell>
        </Table.Row>
      </Table.Header>
      <Table.Body isLoading={fetching} hasError={error}>
        {users && users.map((user) => (
          <Table.Row key={user.accountid+'-'+user.username}>
            <Table.Cell>{user.username}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

const mapStateToTableProps = (state: Store, {group}: Props) => {
  const iamUsersState = state.resources[`iam/groups/${group.accountid}/${group.name}/users`] || {};
  const iamUsers = iamUsersState.data || [];
  return {
    users: iamUsers,
    error: iamUsersState.error,
    fetching: iamUsersState.fetching,
  };
};

const mapDispatchToProps = (dispatch: Dispatch, {group}: Props) => {
  const resource = `iam/groups/${group.accountid}/${group.name}/users`;
  return {
    fetchUsers: () => dispatch(fetchResource(resource)),
  };
};

export default connect(mapStateToTableProps, mapDispatchToProps)(Users);
