import React, {useRef, useState} from 'react';
import {connect} from 'react-redux';
import {FormApi} from 'final-form';
import arrayMutators from 'final-form-arrays';

import {createResource} from 'spectra-logic-ui/actions';
import {WizardFormDialog} from 'spectra-logic-ui/components';
import {Dispatch, Store} from 'spectra-logic-ui';

import {BucketOwnershipControls, FormActionType} from '@/enum';
import {CreateVailBucket} from '@/help';
import {Account, Bucket} from '@/types';
import {createParametersValidator, validateACLs, validateRetention} from '@/buckets/form/pages/validate';
import ConfirmPage from '@/buckets/form/pages/confirm';
import LifecyclePage from '@/buckets/form/pages/lifecycle';
import ParametersPage from '@/buckets/form/pages/parameters';
import AclsPage from '@/buckets/form/pages/permissions/acls';
import PolicyPage from '@/buckets/form/pages/permissions/policy';
import {BucketForm} from '@/buckets/form/enum';
import {FormValues} from '@/buckets/form/types';
import RetentionPage from '@/buckets/form/pages/retention';

type WizardProps = {
  buckets?: Bucket[];
  accounts?: Account[];
  onClose: (...args: any) => any;
  onSubmit: (values: FormValues) => Promise<any>;
}

const CreateBucketWizard = ({onSubmit, buckets = [], accounts = [], ...otherProps}: WizardProps) => {
  const initialValues = {
    control: BucketOwnershipControls.BUCKET_OWNER_ENFORCED,
    compress: false,
    blockPublicAcls: true,
    blockPublicPolicy: true,
    ignorePublicAcls: true,
    restrictPublicBuckets: true,
    locking: false,
  } as any;
  if (accounts.length === 1) {
    initialValues.owner = accounts[0].canonicalId;
  }

  const [values, setValues] = useState({} as FormValues);
  const formRef: React.MutableRefObject<FormApi> = useRef({} as any);
  React.useEffect(() => {
    if (!values.locking && values.defaultRetention !== undefined) {
      formRef.current.mutators.setFormValue('defaultRetention', {});
    }
  }, [values.locking]);

  const validateParameters = createParametersValidator(buckets);

  return (
    <WizardFormDialog
      title={BucketForm.CREATE} initialValues={initialValues} width={820} onSubmit={onSubmit}
      getHelpLocation={() => CreateVailBucket}
      formRef={formRef} mutators={{...arrayMutators}} onValuesChange={setValues} {...otherProps}
    >
      <ParametersPage
        title='Parameters' action={FormActionType.CREATE} accounts={accounts} validate={validateParameters}
      />
      {values.locking && <RetentionPage title='Retention' validate={validateRetention} />}
      <PolicyPage title='Policy' />
      {values.control !== BucketOwnershipControls.BUCKET_OWNER_ENFORCED &&
        <AclsPage title='Access Control Lists' validate={validateACLs} />}
      <LifecyclePage title='Lifecycle' />
      <ConfirmPage title='Confirm' accounts={accounts} />
    </WizardFormDialog>
  );
};

const mapStateToProps = (state: Store) => {
  return {
    buckets: (state.resources.buckets || {}).data,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSubmit: (values: FormValues) => {
    const {...body} = values;
    if (body.control === BucketOwnershipControls.BUCKET_OWNER_ENFORCED) {
      body.acls = [];
    }
    return dispatch(createResource('buckets', body));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateBucketWizard);
