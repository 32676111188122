import React, {useEffect} from 'react';
import {connect} from 'react-redux';

import {Store, Dispatch} from 'spectra-logic-ui';
import {fetchResource} from 'spectra-logic-ui/actions';
import {Card} from 'spectra-logic-ui/components';
import StorageIcon from 'spectra-logic-ui/icons/Storage';
import UpdateAltIcon from 'spectra-logic-ui/icons/SystemUpdateAlt';

import {Pool, Pod} from '@/types';
import ButtonToolbar from '@/components/button_toolbar';
import CardHeader from '@/components/card_header';
import DeleteDialog from '@/components/delete_dialog';
import {StorageRowId} from '@/storage/types';
import AddOnSiteStorageWizard from '@/storage/form/add/on_site_storage';
import EditStorageWizard from '@/storage/form/edit';
import EditEndpointDialog from '@/storage/form/edit_endpoint';
import DeleteStorageWizard from '@/storage/form/delete';
import DeleteEndpointDialog from '@/storage/form/delete_endpoint';
import OnSiteStorageTable from '@/storage/on_site_storage_table';
import {isCloud} from '@/storage/cloud';
import ConsolidateStorageDialog from '@/storage/form/consolidate';
import {TargetType} from '@/enum';

type Props = {
  selectedPool?: Pool;
  selectedRowId: StorageRowId;
  selectRowId: Function;
  clearSelectedRow: () => any;
  fetchEndpoints: Function;
  setOpenDetails: any;
  closeDetails: any;
  pools: Pool[];
  endpoints: Pod[];
}

const OnSiteStorage = (props: Props) => {
  const {selectedPool, selectedRowId, selectRowId, clearSelectedRow, setOpenDetails, closeDetails,
    pools, endpoints=[], fetchEndpoints} = props;

  useEffect(() => {
    fetchEndpoints();
  }, []);

  const addTooltip = 'Select an endpoint to add storage';
  const onSiteTooltip = <React.Fragment>Any storage added will be available for use with Vail lifecycle rules.
   If the {<strong>ADD</strong>} button is disabled, it is because
   you have not selected an endpoint.</React.Fragment>;
  const deleteDialogDescription = 'Are you sure you want to delete this empty storage?';

  const selectedEndpointId = selectedRowId.endpointId;
  const selectedEndpoint = endpoints.find((ep) => ep.id === selectedEndpointId);

  const canAdd = selectedEndpoint;
  const canEdit = (selectedPool && !isCloud(selectedPool)) || selectedEndpoint;
  const canDelete = (selectedPool && !isCloud(selectedPool)) || selectedEndpoint;
  const canCleanup :true|false|undefined = (selectedPool &&
    !isCloud(selectedPool) && (selectedPool.recoverable || selectedPool.target === TargetType.BLACKPEARL));

  const cleanupDialogDescription = 'Are you sure you want to scan all objects to'+
    ' consolidate packs and metadata on this storage?';

  return (
    <React.Fragment>
      <Card>
        <CardHeader icon={StorageIcon} tooltip={onSiteTooltip}>
          Endpoint Storage
        </CardHeader>
        <Card.Body>
          <ButtonToolbar>
            <ButtonToolbar.CreateDialogButton
              title='Add'
              disabled={!canAdd} tooltip={addTooltip}
              dialog={(props: any) => {
                return <AddOnSiteStorageWizard endpoint={selectedEndpointId} {...props} />;
              }}
            />
            <ButtonToolbar.EditDialogButton
              disabled={!canEdit}
              dialog={(props: any) => {
                if (selectedEndpoint) {
                  return <EditEndpointDialog endpoint={selectedEndpoint} {...props} />;
                } else {
                  return <EditStorageWizard pool={selectedPool} {...props} />;
                }
              }}
            />
            <ButtonToolbar.DeleteDialogButton
              disabled={!canDelete}
              dialog={(props: any) => {
                if (selectedEndpoint) {
                  return <DeleteEndpointDialog endpoint={selectedEndpoint} {...props} />;
                } else if (selectedPool) {
                  if (selectedPool.empty) {
                    return <DeleteDialog title='Delete Endpoint Storage' id={selectedRowId.poolId}
                      name={selectedPool.name} description={deleteDialogDescription}
                      resource='storage' {...props}/>;
                  } else {
                    return <DeleteStorageWizard pool={selectedPool} {...props} />;
                  }
                }
                return null;
              }}
              onSuccess={clearSelectedRow}
            />
            <ButtonToolbar.DialogButton
              icon={UpdateAltIcon} title='Consolidate' color='primary'
              disabled={!canCleanup}
              dialog={(props: any) => {
                if (selectedPool) {
                  return <ConsolidateStorageDialog id={selectedRowId.poolId}
                    name={selectedPool.name} description={cleanupDialogDescription}
                    resource='storage' {...props}/>;
                } else {
                  return <ConsolidateStorageDialog resource='storage' {...props}/>;
                }
              }}
              onSuccess={clearSelectedRow}
            />

          </ButtonToolbar>
          <OnSiteStorageTable endpoints={endpoints} pools={pools} selectRowId={selectRowId}
            selectedRowId={selectedRowId} setOpenDetails={setOpenDetails} closeDetails={closeDetails}/>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

const mapStateToProps = (state: Store) => {
  const endpoints = state.resources.endpoints || {};
  return {
    endpoints: endpoints.data || [],
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchEndpoints: () => {
    dispatch(fetchResource('endpoints'));
  },
});

// Does the given storage pool have a cloud storage type.
export const isBP = (storage: Pool) => {
  return isBPStorage(storage.target);
};

// Is the given storage type BlackPearl storage.
export const isBPStorage = (st?: TargetType) => {
  return st === TargetType.BLACKPEARL || st === TargetType.BPLINK || st === TargetType.BPPOLICY;
};

export default connect(mapStateToProps, mapDispatchToProps)(OnSiteStorage);
