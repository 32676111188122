import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {useForm, useFormState} from 'react-final-form';
import makeStyles from '@mui/styles/makeStyles';

import {Dispatch} from 'spectra-logic-ui';
import {resetResource} from 'spectra-logic-ui/actions';
import {WizardPage} from 'spectra-logic-ui/components';

import {Target} from '@/types';
import FormSingleSelect from '@/components/form/single_select';
import DialogDescription from '@/components/form/dialog_description';
import {itemDetails} from '@/storage/form/store';

type Props = {
  title: string;
  targets?: Target[];
  fetching: boolean;
  validate: (formValues: any) => object;
  resetItemDetails: (...args: any) => any;
  onNext?: any;
}

const useStyles = makeStyles({
  singleSelect: {
    width: '40%',
  },
});

const StorageTargetPage = ({title, targets, fetching, resetItemDetails, ...otherProps}: Props) => {
  const classes = useStyles();

  const form = useForm();
  const formState = useFormState();
  useEffect(() => {
    // Initialize the storage target selector to the first one in the list
    // if the user didn't already select one (so navigating back and forth
    // doesn't change the value).
    if (!formState.values.target && targets && targets.length > 0) {
      form.mutators.setFormValue('target', targets[0].id);
    }
  }, [targets]);

  const resetItemSelect = () => {
    form.mutators.setFormValue('item', '');
    resetItemDetails();
  };

  const targetOpts = targets ? targets.map((target) => ({key: target.id, text: target.name})) : [];

  return (
    <WizardPage
      title={title} {...otherProps}
      description={targetOpts.length > 0 ? <DialogDescription>Select a type of storage.</DialogDescription> : undefined}
    >
      {!fetching && targets && targets.length === 0 &&
        <p className='error'>The selected endpoint does not have any storage available to add.</p>
      }
      {targetOpts.length > 0 &&
        <div className={classes.singleSelect}>
          <FormSingleSelect name='target' label='Select Storage Type' options={targetOpts} disabled={fetching}
            onChange={resetItemSelect} />
        </div>
      }
    </WizardPage>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    resetItemDetails: () => dispatch(resetResource(itemDetails)),
  };
};

export default connect(null, mapDispatchToProps)(StorageTargetPage);
