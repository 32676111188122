import React from 'react';
import {connect} from 'react-redux';
import {useFormState} from 'react-final-form';
import {find} from 'lodash';
import makeStyles from '@mui/styles/makeStyles';

import {Store} from 'spectra-logic-ui';
import {WizardPage} from 'spectra-logic-ui/components';

import {Target, TargetItem, TargetItemDetails} from '@/types';
import NoteCard from '@/components/note_card';
import DialogDescription from '@/components/form/dialog_description';
import {isStorageClassArchival} from '@/storage/archive';
import {itemDetails, items} from '@/storage/form/store';

type PageProps = {
  title: string;
  itemDetails?: TargetItemDetails;
  targetItems: TargetItem[];
  targetTypes?: Target[];
}

const useStyles = makeStyles({
  errorCard: {
    marginTop: 10,
  },
  content: {
    '& > *': {
      marginTop: 15,
    },
  },
});

const OnSiteConfirmPage = (props: PageProps) => {
  const {title, itemDetails, targetItems = [], targetTypes, ...otherProps} = props;
  const classes = useStyles();

  const formState = useFormState();
  const name = formState.values.name;
  const item = formState.values.item;
  const target = formState.values.target;
  const storageClass = formState.values.storageClass;
  const cloneRestore = formState.values.cloneRestore;
  const hasRecoverable = formState.values.recoverable !== undefined;
  const recoverable = formState.values.recoverable;
  const cautionThreshold = formState.values.cautionThreshold;
  const warningThreshold = formState.values.warningThreshold;
  const optionalData = formState.values.optionalData;
  const selectedItem = find(targetItems, {id: item});
  const itemName = selectedItem && selectedItem.name ? selectedItem.name : item;
  const targetInfo = targetTypes ? targetTypes.find((t) => t.id === target) : undefined;
  const itemLabel = targetInfo ? targetInfo.name : '';
  return (
    <WizardPage
      title={title} {...otherProps}
      description={<DialogDescription>Verify your configuration is correct.</DialogDescription>}
    >
      <div className={classes.content}>
        <NoteCard title='Parameters'>
          <NoteCard.Table>
            <React.Fragment>
              <NoteCard.TableRow name='Storage Name' value={name}/>
              <NoteCard.TableRow name={itemLabel} value={itemName}/>
              {itemDetails && itemDetails.link &&
               <NoteCard.TableRow name='Link to Bucket' value={formState.values.link}/>}
              <NoteCard.TableRow name='Storage Class' value={storageClass}/>
              {isStorageClassArchival(storageClass) &&
                <NoteCard.TableRow name='Restore To New Clone' value={cloneRestore ? 'Enabled' : 'Disabled'}/>}
              {hasRecoverable &&
                <NoteCard.TableRow name='Third-party Recovery' value={recoverable ? 'Enabled' : 'Disabled'}/>}
              {itemDetails && itemDetails.threshold &&
                <NoteCard.TableRow name='Caution Threshold'
                  value={cautionThreshold ? cautionThreshold + '%' : '--'}/>}
              {itemDetails && itemDetails.threshold &&
                <NoteCard.TableRow name='Warning Threshold'
                  value={warningThreshold ? warningThreshold + '%' : '--'}/>}
              {itemDetails && itemDetails.optional &&
                <NoteCard.TableRow name='Optional Data'
                  value={optionalData ? optionalData + '%' : '--'}/>}
            </React.Fragment>
          </NoteCard.Table>
        </NoteCard>
      </div>
    </WizardPage>
  );
};

const mapStateToProps = (state: Store) => {
  const itemState = state.resources[itemDetails] || {};
  const targetItemsResource = state.resources[items] || {};
  const targetTypesResource = state.resources['targets'] || {};
  return {
    itemDetails: itemState.data,
    targetItems: targetItemsResource.data,
    targetTypes: targetTypesResource.data};
};

export default connect(mapStateToProps)(OnSiteConfirmPage);
